<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>
<script>
// import Vue from 'vue';
export default {
  name: 'Home',
  components: {
  },
  data(){
    return {
      // eventHub: new Vue()
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function(){
    // console.log('app mounted', this, this.$root.eventHub)
  },
  methods: {
  },
}
</script>
<style>
@import './assets/font/font.css';
html{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
body{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.main{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.header{
  position: absolute;
  top: 0;
  height: 50px;
  left: 0;
  right: 0;
}
.footer{
  position: absolute;
  bottom: 0;
  height: 50px;
  left: 0;
  right: 0;
}
.content{
  position: absolute;
  bottom: 0;
  height: 50px;
  left: 0;
  right: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  width: 100%;
  height: 100%;
  display: flex;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.alarm-high{
  color: red !important;
}
.alarm-low{
  color: red !important;
}
.status-blue{
  color: blue !important;
}
.status-green{
  color: #0AC937 !important;
}
</style>
