"use strict";

import Vue from 'vue';

const imgs = {
  app: {
    download: `//${Vue.domains.imgs}/imgs/home/download.png`,
    road: `//${Vue.domains.imgs}/imgs/app/road.png`,
    pole: `//${Vue.domains.imgs}/imgs/app/pole.png`,
    factory: `//${Vue.domains.imgs}/imgs/app/factory.png`,
    appLogo: `//${Vue.domains.imgs}/imgs/app/edition/${Vue.domains.appUrl}/amdm.png`, //${Vue.domains.appUrl}
  },
  gis:{
    tree:{
      stop: `//${Vue.domains.imgs}/imgs/gis/icon230819/tree/stop.png`,
      repair: `//${Vue.domains.imgs}/imgs/gis/icon230819/tree/repair.png`,
      lighting: `//${Vue.domains.imgs}/imgs/gis/icon230819/tree/lighting.png`,
      lightingPolice: `//${Vue.domains.imgs}/imgs/gis/icon230819/tree/lightingPolice.png`,
      offline: `//${Vue.domains.imgs}/imgs/gis/icon230819/tree/offline.png`,
      out: `//${Vue.domains.imgs}/imgs/gis/icon230819/tree/out.png`,
      outPolice: `//${Vue.domains.imgs}/imgs/gis/icon230819/tree/outPolice.png`
    },
    box:{
      lightingBig: `//${Vue.domains.imgs}/imgs/gis/icon230819/box/lighting-big.png`,
      lightingPoliceBig: `//${Vue.domains.imgs}/imgs/gis/icon230819/box/lighting-police-big.png`,
      offlineBig: `//${Vue.domains.imgs}/imgs/gis/icon230819/box/offline-big.png`,
      outBig: `//${Vue.domains.imgs}/imgs/gis/icon230819/box/out-big.png`,
      outPoliceBig: `//${Vue.domains.imgs}/imgs/gis/icon230819/box/out-police-big.png`,

    
      lighting: `//${Vue.domains.imgs}/imgs/gis/icon230819/box/lighting.png`,
      lightingPolice: `//${Vue.domains.imgs}/imgs/gis/icon230819/box/lighting-police.png`,
      offline: `//${Vue.domains.imgs}/imgs/gis/icon230819/box/offline.png`,
      out: `//${Vue.domains.imgs}/imgs/gis/icon230819/box/out.png`,
      outPolice: `//${Vue.domains.imgs}/imgs/gis/icon230819/box/out-police.png`
    },
    pole:{
      lighting1: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/lighting1.png`,
      lighting2: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/lighting2.png`,
      lighting3: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/lighting3.png`,
      lighting4: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/lighting4.png`,
      out0: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/out0.png`,
      out1: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/out1.png`,
      out2: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/out2.png`,
      out3: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/out3.png`,
      out4: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/out4.png`,
      lightingP1: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/lightingP1.png`,
      lightingP2: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/lightingP2.png`,
      lightingP3: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/lightingP3.png`,
      lightingP4: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/lightingP4.png`,
      outOff1: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/outOff1.png`,
      outOff2: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/outOff2.png`,
      outOff3: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/outOff3.png`,
      outOff4: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/outOff4.png`,
      outP1: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/outP1.png`,
      outP2: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/outP2.png`,
      outP3: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/outP3.png`,
      outP4: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/outP4.png`,
      offline: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/offline.png`,
      police: `//${Vue.domains.imgs}/imgs/gis/icon230819/pole/police.png`,
    },
  },
  lux: {
    empty: `//${Vue.domains.imgs}/imgs/lux-20220429/luxbg.png`,
    nav: {
      bulletin: `//${Vue.domains.imgs}/imgs/pole20220809/lux/bulletin.png`,
      bulletin2: `//${Vue.domains.imgs}/imgs/pole20220809/lux/bulletin2.png`,
      data: `//${Vue.domains.imgs}/imgs/pole20220809/lux/data.png`,
      data2: `//${Vue.domains.imgs}/imgs/pole20220809/lux/data2.png`,
      lamp: `//${Vue.domains.imgs}/imgs/pole20220809/lux/lamp.png`,
      lamp2: `//${Vue.domains.imgs}/imgs/pole20220809/lux/lamp2.png`,
      setUp: `//${Vue.domains.imgs}/imgs/pole20220809/lux/setUp.png`,
      setUp2: `//${Vue.domains.imgs}/imgs/pole20220809/lux/setUp2.png`,
      treeIcon: `//${Vue.domains.imgs}/imgs/pole20220809/lux/treeIcon.png`,
      treeIcon2: `//${Vue.domains.imgs}/imgs/pole20220809/lux/treeIcon2.png`,
      treeIcon3: `//${Vue.domains.imgs}/imgs/pole20220809/lux/treeIcon3.png`,
      treeIcon4: `//${Vue.domains.imgs}/imgs/pole20220809/lux/treeIcon4.png`,

      treeIconLarge: `//${Vue.domains.imgs}/imgs/pole20220809/lux/treeIconLarge.png`,
      treeIconLarge2: `//${Vue.domains.imgs}/imgs/pole20220809/lux/treeIconLarge2.png`,
      treeIconLarge3: `//${Vue.domains.imgs}/imgs/pole20220809/lux/treeIconLarge3.png`,
      treeIconLarge4: `//${Vue.domains.imgs}/imgs/pole20220809/lux/treeIconLarge4.png`,
      luxMix0: `//${Vue.domains.imgs}/imgs/pole20220809/lux/luxMix0.png`,
      offline: `//${Vue.domains.imgs}/imgs/pole20220809/lux/offline.png`,
      online: `//${Vue.domains.imgs}/imgs/pole20220809/lux/online.png`,
      police: `//${Vue.domains.imgs}/imgs/pole20220809/lux/police.png`,

      humidity: `//${Vue.domains.imgs}/imgs/pole20220809/lux/humidity.png`,
      luxVal: `//${Vue.domains.imgs}/imgs/pole20220809/lux/luxVal.png`,
      monitor: `//${Vue.domains.imgs}/imgs/pole20220809/lux/monitor.png`,
      temperature: `//${Vue.domains.imgs}/imgs/pole20220809/lux/temperature.png`,
      time: `//${Vue.domains.imgs}/imgs/pole20220809/lux/time.png`,
    }
  },
  home: {
    header: {
      center: `//${Vue.domains.imgs}/imgs/home/header-center.png`,
      left: `//${Vue.domains.imgs}/imgs/home/header-line-left.png`,
      right: `//${Vue.domains.imgs}/imgs/home/header-line-right.png`,
      user: `//${Vue.domains.imgs}/imgs/home/header-user.png`,
    },
    content: {
      bg: `//${Vue.domains.imgs}/imgs/home/bg.png`,
      hemoBg: `//${Vue.domains.imgs}/imgs/home/hemoBg.png`,
    },
    switchRoles: {
      organize: `//${Vue.domains.imgs}/imgs/home/switchRoles/organize.png`,
      organizeActive: `//${Vue.domains.imgs}/imgs/home/switchRoles/organizeActive.png`,
      streetLamp: `//${Vue.domains.imgs}/imgs/home/switchRoles/streetLamp.png`,
      lamp: `//${Vue.domains.imgs}/imgs/home/switchRoles/lamp.png`,
      factory: `//${Vue.domains.imgs}/imgs/home/switchRoles/factory.png`,
    },
    apps: {
      road: `//${Vue.domains.imgs}/imgs/home/app-light-road.png`,
      factory: `//${Vue.domains.imgs}/imgs/home/app-light-factory.png`,
      landscape: `//${Vue.domains.imgs}/imgs/home/app-light-landscape.png`,
      educate: `//${Vue.domains.imgs}/imgs/home/app-light-educate.png`,
      tunnel: `//${Vue.domains.imgs}/imgs/home/app-light-tunnel.png`,
      env: `//${Vue.domains.imgs}/imgs/home/app-env.png`,
      manhole: `//${Vue.domains.imgs}/imgs/home/app-manhole.png`,
      pole: `//${Vue.domains.imgs}/imgs/home/app-pole.png`,
      led: `//${Vue.domains.imgs}/imgs/home/app-led.png`,
      video: `//${Vue.domains.imgs}/imgs/home/app-video.png`,
      site: `//${Vue.domains.imgs}/imgs/home/app-led.png`,
      app: `//${Vue.domains.imgs}/imgs/home/app-led.png`,
      cust: `//${Vue.domains.imgs}/imgs/home/app-led.png`,
    },
    page: { //首页icon
      assetsBox: `//${Vue.domains.imgs}/imgs/home/assetsBox.png`,
      assetsCable: `//${Vue.domains.imgs}/imgs/home/assetsCable.png`,
      assetsLampPole: `//${Vue.domains.imgs}/imgs/home/assetsLampPole.png`,
      assetsLamps: `//${Vue.domains.imgs}/imgs/home/assetsLamps.png`,
      policeRepair: `//${Vue.domains.imgs}/imgs/home/policeRepair.png`,
      policeNum: `//${Vue.domains.imgs}/imgs/home/policeNum.png`,
      policeNormal: `//${Vue.domains.imgs}/imgs/home/policeNormal.png`,
      policeLine: `//${Vue.domains.imgs}/imgs/home/policeLine.png`,
      dat: `//${Vue.domains.imgs}/imgs/home/dat.png`,
      location: `//${Vue.domains.imgs}/imgs/home/location.png`,
      numBg: `//${Vue.domains.imgs}/imgs/home/numBg.png`,
      policeBoxBg: `//${Vue.domains.imgs}/imgs/home/policeBoxBg.png`,
      policeLampBg: `//${Vue.domains.imgs}/imgs/home/policeLampBg.png`,
      policeBox: `//${Vue.domains.imgs}/imgs/home/policeBox.png`,
      policeLamp: `//${Vue.domains.imgs}/imgs/home/policeLamp.png`,
      lightNum: `//${Vue.domains.imgs}/imgs/home/lightNum.png`,
      numberBg: `//${Vue.domains.imgs}/imgs/home/numberBg.png`,
    },
    switchOrganization: {
      switchLogo: `//${Vue.domains.imgs}/imgs/pole20220809/switchOrganization/switchLogo.png`,
      cancel: `//${Vue.domains.imgs}/imgs/pole20220809/switchOrganization/cancel.png`,
      select: `//${Vue.domains.imgs}/imgs/pole20220809/switchOrganization/select.png`,
    },
  },
  tree: {
    root: {
      common: `//${Vue.domains.imgs}/imgs/tree/root.png`,
      select: `//${Vue.domains.imgs}/imgs/tree/root.png`,
    },
    group: {
      common: `//${Vue.domains.imgs}/imgs/tree/group.png`,
      select: `//${Vue.domains.imgs}/imgs/tree/group_select.png`
    },
    station: {
      offline: `//${Vue.domains.imgs}/imgs/tree-20220407/offline.png`,
      offliner: `//${Vue.domains.imgs}/imgs/tree-20220407/offline-r.png`,
      offlineb: `//${Vue.domains.imgs}/imgs/tree-20220407/offline-b.png`,
      onlineOn: `//${Vue.domains.imgs}/imgs/tree-20220407/online-on.png`,
      onlineOff: `//${Vue.domains.imgs}/imgs/tree-20220407/online-off.png`,
      alarmOn: `//${Vue.domains.imgs}/imgs/tree-20220407/alarm-on.png`,
      alarmOff: `//${Vue.domains.imgs}/imgs/tree-20220407/alarm-off.png`,
    },
    carat: {

    },
  },
  pole: {
    gis: {
      menu: {
        up: `//${Vue.domains.imgs}/imgs/pole20220708/menu-up.png`,
        down: `//${Vue.domains.imgs}/imgs/pole20220708/menu-down.png`,
      }
    },
    light: {
      icon: {
        base: `//${Vue.domains.imgs}/imgs/pole/light.png`,
      },
      analyse: {
        total: `//${Vue.domains.imgs}/imgs/pole20220701/light-total.png`,
        on: `//${Vue.domains.imgs}/imgs/pole20220701/light-on.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220701/light-online.png`,
        energy: `//${Vue.domains.imgs}/imgs/pole20220701/light-energy.png`,
      },
    },
    asset: {
      level1: {
        total: `//${Vue.domains.imgs}/imgs/pole20220630/asset-total.png`,
        running: `//${Vue.domains.imgs}/imgs/pole20220630/asset-running.png`,
        alarm: `//${Vue.domains.imgs}/imgs/pole20220630/asset-alarm.png`,
        create: `//${Vue.domains.imgs}/imgs/pole20220630/asset-create.png`,
        day: `//${Vue.domains.imgs}/imgs/pole20220630/useage-day.png`,
        month: `//${Vue.domains.imgs}/imgs/pole20220630/useage-month.png`,
        utotal: `//${Vue.domains.imgs}/imgs/pole20220630/useage-total.png`,
      },
      level2: {
        box: `//${Vue.domains.imgs}/imgs/pole20220630/asset-box.png`,
        broad: `//${Vue.domains.imgs}/imgs/pole20220630/asset-broad.png`,
        charge: `//${Vue.domains.imgs}/imgs/pole20220630/asset-charge.png`,
        elec: `//${Vue.domains.imgs}/imgs/pole20220630/asset-elec.png`,
        env: `//${Vue.domains.imgs}/imgs/pole20220630/asset-env.png`,
        gryo: `//${Vue.domains.imgs}/imgs/pole20220630/asset-gryo.png`,
        led: `//${Vue.domains.imgs}/imgs/pole20220630/asset-led.png`,
        light: `//${Vue.domains.imgs}/imgs/pole20220630/asset-light.png`,
        lock: `//${Vue.domains.imgs}/imgs/pole20220630/asset-lock.png`,
        speak: `//${Vue.domains.imgs}/imgs/pole20220630/asset-speak.png`,
        video: `//${Vue.domains.imgs}/imgs/pole20220630/asset-video.png`,
        water: `//${Vue.domains.imgs}/imgs/pole20220630/asset-water.png`,
      },
      level3: {
        total2: `//${Vue.domains.imgs}/imgs/pole20220809/level/total2.png`,
        total3: `//${Vue.domains.imgs}/imgs/pole20220809/level/total3.png`,
        total4: `//${Vue.domains.imgs}/imgs/pole20220809/level/total4.png`,
        total5: `//${Vue.domains.imgs}/imgs/pole20220809/level/total5.png`,
        total6: `//${Vue.domains.imgs}/imgs/pole20220809/level/total6.png`,
        total7: `//${Vue.domains.imgs}/imgs/pole20220809/level/total7.png`,
        total8: `//${Vue.domains.imgs}/imgs/pole20220809/level/total8.png`,
        total9: `//${Vue.domains.imgs}/imgs/pole20220809/level/total9.png`,
        total10: `//${Vue.domains.imgs}/imgs/pole20220809/level/total10.png`,
        total11: `//${Vue.domains.imgs}/imgs/pole20220809/level/total11.png`,
        running: `//${Vue.domains.imgs}/imgs/pole20220809/level/running.png`,
        alarm: `//${Vue.domains.imgs}/imgs/pole20220809/level/alarm.png`,
        create: `//${Vue.domains.imgs}/imgs/pole20220809/level/create.png`,
        day: `//${Vue.domains.imgs}/imgs/pole20220809/level/day.png`,
        month: `//${Vue.domains.imgs}/imgs/pole20220809/level/month.png`,
        utotal: `//${Vue.domains.imgs}/imgs/pole20220809/level/utotal.png`,
      },
      index: {
        date: `//${Vue.domains.imgs}/imgs/pole20220809/index/date.png`,
        name: `//${Vue.domains.imgs}/imgs/pole20220809/index/name.png`,
        pole: `//${Vue.domains.imgs}/imgs/pole20220809/index/pole.png`,
        type: `//${Vue.domains.imgs}/imgs/pole20220809/index/type.png`,
        cover: {
          angle: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/angle.png`,
          cell: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/cell.png`,
          gas: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/gas.png`,
          interference: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/interference.png`,
          location: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/location.png`,
          Overflow: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/Overflow.png`,
          RSSI: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/RSSI.png`,
          shock: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/shock.png`,
          signal: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/signal.png`,
          temperature: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/temperature.png`,
          time: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/time.png`,
          XLEL: `//${Vue.domains.imgs}/imgs/pole20220809/index/cover/XLEL.png`,
        },
        environment: {
          atmos: `//${Vue.domains.imgs}/imgs/pole20220809/index/environment/atmos.png`,
          brightness: `//${Vue.domains.imgs}/imgs/pole20220809/index/environment/brightness.png`,
          humidity: `//${Vue.domains.imgs}/imgs/pole20220809/index/environment/humidity.png`,
          noise: `//${Vue.domains.imgs}/imgs/pole20220809/index/environment/noise.png`,
          PM10: `//${Vue.domains.imgs}/imgs/pole20220809/index/environment/PM10.png`,
          PM25: `//${Vue.domains.imgs}/imgs/pole20220809/index/environment/PM25.png`,
          temperature: `//${Vue.domains.imgs}/imgs/pole20220809/index/environment/temperature.png`,
          windAngle: `//${Vue.domains.imgs}/imgs/pole20220809/index/environment/windAngle.png`,
          windGrade: `//${Vue.domains.imgs}/imgs/pole20220809/index/environment/windGrade.png`,
          windPower: `//${Vue.domains.imgs}/imgs/pole20220809/index/environment/windPower.png`,
          windSpeed: `//${Vue.domains.imgs}/imgs/pole20220809/index/environment/windSpeed.png`,
        },
      },
    },
    icons: {
      direction: {
        left: `//${Vue.domains.imgs}/imgs/direction20220713/left.png`,
        lower: `//${Vue.domains.imgs}/imgs/direction20220713/lower.png`,
        right: `//${Vue.domains.imgs}/imgs/direction20220713/right.png`,
        upper: `//${Vue.domains.imgs}/imgs/direction20220713/upper.png`,
        lowerLeft: `//${Vue.domains.imgs}/imgs/direction20220713/lower-left.png`,
        lowerRight: `//${Vue.domains.imgs}/imgs/direction20220713/lower-right.png`,
        upperLeft: `//${Vue.domains.imgs}/imgs/direction20220713/upper-left.png`,
        upperRight: `//${Vue.domains.imgs}/imgs/direction20220713/upper-right.png`,

        aperture: `//${Vue.domains.imgs}/imgs/direction20220713/aperture.png`,
        focal: `//${Vue.domains.imgs}/imgs/direction20220713/focal.png`,
        shot: `//${Vue.domains.imgs}/imgs/direction20220713/shot.png`,

        vehicleId: `//${Vue.domains.imgs}/imgs/direction20220713/policeInfo-vehicleId.png`,
        time: `//${Vue.domains.imgs}/imgs/direction20220713/policeInfo-time.png`,
        camera: `//${Vue.domains.imgs}/imgs/direction20220713/policeInfo-camera.png`,
        automobile: `//${Vue.domains.imgs}/imgs/direction20220713/policeInfo-automobile.png`,


      },
      links: {
        broad: `//${Vue.domains.imgs}/imgs/pole20220627/link-broad.png`,
        charge: `//${Vue.domains.imgs}/imgs/pole20220627/link-charge.png`,
        garbage: `//${Vue.domains.imgs}/imgs/pole20220627/link-garbage.png`,
        lock: `//${Vue.domains.imgs}/imgs/pole20220627/link-lock.png`,
        speak: `//${Vue.domains.imgs}/imgs/pole20220627/link-speak.png`,
        video: `//${Vue.domains.imgs}/imgs/pole20220627/link-video.png`,
        wifi: `//${Vue.domains.imgs}/imgs/pole20220627/link-wifi.png`,
        light: `//${Vue.domains.imgs}/imgs/pole20220627/link-light.png`,
        led: `//${Vue.domains.imgs}/imgs/pole20220627/link-led.png`,
        env: `//${Vue.domains.imgs}/imgs/pole20220627/link-env.png`,
        manhole: `//${Vue.domains.imgs}/imgs/pole20220627/link-manhole.png`,
        phone: `//${Vue.domains.imgs}/imgs/pole20220627/link-phone.png`,
        traffic: `//${Vue.domains.imgs}/imgs/pole20220627/link-traffic.png`,
      },
      tree: {
        root: `//${Vue.domains.imgs}/imgs/pole20220624/tree-root.png`,
        group: `//${Vue.domains.imgs}/imgs/pole20220624/tree-group.png`,
        collect: `//${Vue.domains.imgs}/imgs/pole20220624/tree-collect.png`,
        expand: `//${Vue.domains.imgs}/imgs/pole20220624/tree-expand.png`,
      },
      pole: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220624/pole-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220624/pole-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220624/pole-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220624/pole-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220624/pole-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220624/pole-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220624/pole-repair.png`,
      },
      light: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220624/light-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220624/light-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220624/light-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220624/light-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220624/light-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220624/light-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220624/light-repair.png`,
      },
      video: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220624/video-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220624/video-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220624/video-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220624/video-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220624/video-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220624/video-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220624/video-repair.png`,

        aperture: `//${Vue.domains.imgs}/imgs/pole20220809/camera/aperture.png`,
        denoise: `//${Vue.domains.imgs}/imgs/pole20220809/camera/denoise.png`,
        rainproof: `//${Vue.domains.imgs}/imgs/pole20220809/camera/rainproof.png`,
        voice: `//${Vue.domains.imgs}/imgs/pole20220809/camera/voice.png`,
        hd: `//${Vue.domains.imgs}/imgs/pole20220809/camera/hd.png`,
        presetAdd: `//${Vue.domains.imgs}/imgs/pole20220809/camera/presetAdd.png`,
        presetDel: `//${Vue.domains.imgs}/imgs/pole20220809/camera/presetDel.png`,



        camera: `//${Vue.domains.imgs}/imgs/pole20220809/camera/camera.png`,
        collect: `//${Vue.domains.imgs}/imgs/pole20220809/camera/collect.png`,
        play: `//${Vue.domains.imgs}/imgs/pole20220809/camera/play.png`,
        camera2: `//${Vue.domains.imgs}/imgs/pole20220809/camera/camera2.png`,
        collect2: `//${Vue.domains.imgs}/imgs/pole20220809/camera/collect2.png`,
        play2: `//${Vue.domains.imgs}/imgs/pole20220809/camera/play2.png`,
      },
      broad: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220624/broad-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220624/broad-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220624/broad-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220624/broad-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220624/broad-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220624/broad-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220624/broad-repair.png`,

        cease: `//${Vue.domains.imgs}/imgs/pole20220809/state3/broad-cease.png`,
        deviceName: `//${Vue.domains.imgs}/imgs/pole20220809/state3/broad-deviceName.png`,
        Manual: `//${Vue.domains.imgs}/imgs/pole20220809/state3/broad-Manual.png`,
        Plan: `//${Vue.domains.imgs}/imgs/pole20220809/state3/broad-Plan.png`,
        PlayPlan: `//${Vue.domains.imgs}/imgs/pole20220809/state3/broad-PlayPlan.png`,
        run: `//${Vue.domains.imgs}/imgs/pole20220809/state3/broad-run.png`,
        Start: `//${Vue.domains.imgs}/imgs/pole20220809/state3/broad-Start.png`,
        TaskPlan: `//${Vue.domains.imgs}/imgs/pole20220809/state3/broad-TaskPlan.png`,

        offline2: `//${Vue.domains.imgs}/imgs/pole20220809/state/broad-offline.png`,
        online2: `//${Vue.domains.imgs}/imgs/pole20220809/state/broad-online.png`,
        police2: `//${Vue.domains.imgs}/imgs/pole20220809/state/broad-police.png`,
        offline3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/broad-offline.png`,
        online3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/broad-online.png`,
        police3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/broad-police.png`,
      },
      speak: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220624/speak-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220624/speak-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220624/speak-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220624/speak-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220624/speak-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220624/speak-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220624/speak-repair.png`,

        offline2: `//${Vue.domains.imgs}/imgs/pole20220809/state/speak-offline.png`,
        online2: `//${Vue.domains.imgs}/imgs/pole20220809/state/speak-online.png`,
        police2: `//${Vue.domains.imgs}/imgs/pole20220809/state/speak-police.png`,
        offline3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/speak-offline.png`,
        online3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/speak-online.png`,
        police3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/speak-police.png`,
      },
      led: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220706/led-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220706/led-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220706/led-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220706/led-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220706/led-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220706/led-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220706/led-repair.png`,

        offline2: `//${Vue.domains.imgs}/imgs/pole20220809/state/led-offline.png`,
        online2: `//${Vue.domains.imgs}/imgs/pole20220809/state/led-online.png`,
        police2: `//${Vue.domains.imgs}/imgs/pole20220809/state/led-police.png`,

        brightness: `//${Vue.domains.imgs}/imgs/pole20220809/state3/led-brightness.png`,
        manage: `//${Vue.domains.imgs}/imgs/pole20220809/state3/led-manage.png`,
        Manualplay: `//${Vue.domains.imgs}/imgs/pole20220809/state3/led-Manualplay.png`,
        playback: `//${Vue.domains.imgs}/imgs/pole20220809/state3/led-playback.png`,
        restart: `//${Vue.domains.imgs}/imgs/pole20220809/state3/led-restart.png`,
        screenshot: `//${Vue.domains.imgs}/imgs/pole20220809/state3/led-screenshot.png`,
        Start: `//${Vue.domains.imgs}/imgs/pole20220809/state3/led-Start.png`,
      },
      env: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220706/env-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220706/env-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220706/env-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220706/env-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220706/env-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220706/env-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220706/env-repair.png`,


        test: `//${Vue.domains.imgs}/imgs/pole20220809/state/env-test.png`,

        offline2: `//${Vue.domains.imgs}/imgs/pole20220809/state/env-offline.png`,
        online2: `//${Vue.domains.imgs}/imgs/pole20220809/state/env-online.png`,
        police2: `//${Vue.domains.imgs}/imgs/pole20220809/state/env-police.png`,
        offline3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/env-offline.png`,
        online3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/env-online.png`,
        police3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/env-police.png`,
      },
      wifi: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220706/wifi-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220706/wifi-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220706/wifi-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220706/wifi-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220706/wifi-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220706/wifi-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220706/wifi-repair.png`,

        offline2: `//${Vue.domains.imgs}/imgs/pole20220809/state/wifi-offline.png`,
        online2: `//${Vue.domains.imgs}/imgs/pole20220809/state/wifi-online.png`,
        police2: `//${Vue.domains.imgs}/imgs/pole20220809/state/wifi-police.png`,

        offline3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/wifi-offline.png`,
        online3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/wifi-online.png`,
        police3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/wifi-police.png`,

        activeUser: `//${Vue.domains.imgs}/imgs/pole20220809/state3/wifi-activeUser.png`,
        broadcast: `//${Vue.domains.imgs}/imgs/pole20220809/state3/wifi-broadcast.png`,
        flow: `//${Vue.domains.imgs}/imgs/pole20220809/state3/wifi-flow.png`,
        frequency: `//${Vue.domains.imgs}/imgs/pole20220809/state3/wifi-frequency.png`,
        RunTime: `//${Vue.domains.imgs}/imgs/pole20220809/state3/wifi-RunTime.png`,
        ssid: `//${Vue.domains.imgs}/imgs/pole20220809/state3/wifi-ssid.png`,
        storage: `//${Vue.domains.imgs}/imgs/pole20220809/state3/wifi-storage.png`,
        user: `//${Vue.domains.imgs}/imgs/pole20220809/state3/wifi-user.png`,


      },
      charge: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220706/charge-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220706/charge-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220706/charge-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220706/charge-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220706/charge-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220706/charge-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220706/charge-repair.png`,

        offline2: `//${Vue.domains.imgs}/imgs/pole20220809/state/charge-offline.png`,
        online2: `//${Vue.domains.imgs}/imgs/pole20220809/state/charge-online.png`,
        police2: `//${Vue.domains.imgs}/imgs/pole20220809/state/charge-police.png`,
        offline3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/charge-offline.png`,
        online3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/charge-online.png`,
        police3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/charge-police.png`,
      },
      manhole: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-repair.png`,


        offline2: `//${Vue.domains.imgs}/imgs/pole20220809/state/manhole-offline.png`,
        online2: `//${Vue.domains.imgs}/imgs/pole20220809/state/manhole-online.png`,
        police2: `//${Vue.domains.imgs}/imgs/pole20220809/state/manhole-police.png`,
        offline3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/manhole-offline.png`,
        online3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/manhole-online.png`,
        police3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/manhole-police.png`,
      },
      garbage: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220706/garbage-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220706/garbage-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220706/garbage-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220706/garbage-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220706/garbage-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220706/garbage-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220706/garbage-repair.png`,

        offline2: `//${Vue.domains.imgs}/imgs/pole20220809/state/garbage-offline.png`,
        online2: `//${Vue.domains.imgs}/imgs/pole20220809/state/garbage-online.png`,
        police2: `//${Vue.domains.imgs}/imgs/pole20220809/state/garbage-police.png`,

        offline3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/garbage-offline.png`,
        online3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/garbage-online.png`,
        police3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/garbage-police.png`,
      },
      lock: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220706/lock-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220706/lock-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220706/lock-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220706/lock-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220706/lock-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220706/lock-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220706/lock-repair.png`,
      },
      phone: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220706/phone-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220706/phone-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220706/phone-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220706/phone-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220706/phone-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220706/phone-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220706/phone-repair.png`,


        offline2: `//${Vue.domains.imgs}/imgs/pole20220809/state/phone-offline.png`,
        online2: `//${Vue.domains.imgs}/imgs/pole20220809/state/phone-online.png`,
        police2: `//${Vue.domains.imgs}/imgs/pole20220809/state/phone-police.png`,
        offline3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/phone-offline.png`,
        online3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/phone-online.png`,
        police3: `//${Vue.domains.imgs}/imgs/pole20220809/state3/phone-police.png`,
      },
      traffic: {
        alarm10: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-alarm10.png`,
        alarm20: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-alarm20.png`,
        alarm30: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-alarm30.png`,
        online: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-offline.png`,
        stop: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-stop.png`,
        repair: `//${Vue.domains.imgs}/imgs/pole20220706/manhole-repair.png`,
      },
    },
    header: {
      bg: `//${Vue.domains.imgs}/imgs/pole20220602/header-bg.png`,
      btn: `//${Vue.domains.imgs}/imgs/pole20220602/header-btn.png`,
    },
    slider: {
      menu: {
        broad: `//${Vue.domains.imgs}/imgs/pole20220707/broad-common.png`,
        charge: `//${Vue.domains.imgs}/imgs/pole20220707/charge-common.png`,
        env: `//${Vue.domains.imgs}/imgs/pole20220707/env-common.png`,
        garbage: `//${Vue.domains.imgs}/imgs/pole20220707/garbage-common.png`,
        led: `//${Vue.domains.imgs}/imgs/pole20220707/led-common.png`,
        light: `//${Vue.domains.imgs}/imgs/pole20220707/light-common.png`,
        manhole: `//${Vue.domains.imgs}/imgs/pole20220707/manhole-common.png`,
        phone: `//${Vue.domains.imgs}/imgs/pole20220707/phone-common.png`,
        speak: `//${Vue.domains.imgs}/imgs/pole20220707/speak-common.png`,
        traffic: `//${Vue.domains.imgs}/imgs/pole20220707/traffic-common.png`,
        video: `//${Vue.domains.imgs}/imgs/pole20220707/video-common.png`,
        wifi: `//${Vue.domains.imgs}/imgs/pole20220707/wifi-common.png`,
      },
      active: {
        broad: `//${Vue.domains.imgs}/imgs/pole20220707/broad-selected.png`,
        charge: `//${Vue.domains.imgs}/imgs/pole20220707/charge-selected.png`,
        env: `//${Vue.domains.imgs}/imgs/pole20220707/env-selected.png`,
        garbage: `//${Vue.domains.imgs}/imgs/pole20220707/garbage-selected.png`,
        led: `//${Vue.domains.imgs}/imgs/pole20220707/led-selected.png`,
        light: `//${Vue.domains.imgs}/imgs/pole20220707/light-selected.png`,
        manhole: `//${Vue.domains.imgs}/imgs/pole20220707/manhole-selected.png`,
        phone: `//${Vue.domains.imgs}/imgs/pole20220707/phone-selected.png`,
        speak: `//${Vue.domains.imgs}/imgs/pole20220707/speak-selected.png`,
        traffic: `//${Vue.domains.imgs}/imgs/pole20220707/traffic-selected.png`,
        video: `//${Vue.domains.imgs}/imgs/pole20220707/video-selected.png`,
        wifi: `//${Vue.domains.imgs}/imgs/pole20220707/wifi-selected.png`,
      },
    },
    homePage: {
      upperBaseIcon: `//${Vue.domains.imgs}/imgs/pole/homePage/upper-base-icon.png`,
      upperBase: `//${Vue.domains.imgs}/imgs/pole/homePage/upper-base.png`,
      upperBasePlus: `//${Vue.domains.imgs}/imgs/pole/homePage/upper-base-plus.png`,

      middleBut: `//${Vue.domains.imgs}/imgs/pole/homePage/middle-but.png`,
      middleBut1: `//${Vue.domains.imgs}/imgs/pole/homePage/middle-but1.png`,

      lowerBase: `//${Vue.domains.imgs}/imgs/pole/homePage/lower-base.png`,
      lowerBasePlus: `//${Vue.domains.imgs}/imgs/pole/homePage/lower-base-plus.png`,

      alarmsNum: `//${Vue.domains.imgs}/imgs/pole/homePage/alarmsNum.gif`,
      LightupNum: `//${Vue.domains.imgs}/imgs/pole/homePage/LightupNum.gif`,
      OnlineNum: `//${Vue.domains.imgs}/imgs/pole/homePage/OnlineNum.gif`,
      repairNum: `//${Vue.domains.imgs}/imgs/pole/homePage/repairNum.gif`,

      CriticalAlarm: `//${Vue.domains.imgs}/imgs/pole/homePage/CriticalAlarm.gif`,
      GeneralAlarm: `//${Vue.domains.imgs}/imgs/pole/homePage/GeneralAlarm.gif`,

      HeadLine: `//${Vue.domains.imgs}/imgs/pole/homePage/HeadLine.gif`,

      leftBut: `//${Vue.domains.imgs}/imgs/pole/homePage/leftBut.png`,
      rigBut: `//${Vue.domains.imgs}/imgs/pole/homePage/rigBut.png`,
      screen: `//${Vue.domains.imgs}/imgs/pole/homePage/screen.png`,
      circular: `//${Vue.domains.imgs}/imgs/pole/homePage/circular.png`,

      warn: `//${Vue.domains.imgs}/imgs/pole/homePage/warn.png`,
      warnBg: `//${Vue.domains.imgs}/imgs/pole/homePage/warnBg.png`,

      effect: `//${Vue.domains.imgs}/imgs/pole/homePage/effect.gif`,
      effectHumidity: `//${Vue.domains.imgs}/imgs/pole/homePage/effect-humidity.png`,
      effectInfo: `//${Vue.domains.imgs}/imgs/pole/homePage/effect-info.png`,
      effectLight: `//${Vue.domains.imgs}/imgs/pole/homePage/effect-light.png`,
      effectPolice: `//${Vue.domains.imgs}/imgs/pole/homePage/effect-police.png`,
      effectRadio: `//${Vue.domains.imgs}/imgs/pole/homePage/effect-radio.png`,
      effectVideo: `//${Vue.domains.imgs}/imgs/pole/homePage/effect-video.png`,
      effectWifi: `//${Vue.domains.imgs}/imgs/pole/homePage/effect-wifi.png`,
    },
    home: {
      bg: `//${Vue.domains.imgs}/imgs/pole/home/bg.png`,
      bg2: `//${Vue.domains.imgs}/imgs/pole/home/bg2.jpg`,
      dev: {
        bg: `//${Vue.domains.imgs}/imgs/pole/home/top-left.png`,
        icon: `//${Vue.domains.imgs}/imgs/pole/home/dev-icon.png`,
      },
      light: {
        bg: `//${Vue.domains.imgs}/imgs/pole/home/left-bottom.png`,
      },
      online: {
        bg: `//${Vue.domains.imgs}/imgs/pole/home/center-bottom.png`,
        pole: `//${Vue.domains.imgs}/imgs/pole/home/dev-pole.png`,
        led: `//${Vue.domains.imgs}/imgs/pole/home/dev-led.png`,
        env: `//${Vue.domains.imgs}/imgs/pole/home/dev-env.png`,
        video: `//${Vue.domains.imgs}/imgs/pole/home/dev-video.png`,
        broad: `//${Vue.domains.imgs}/imgs/pole/home/dev-broad.png`,
        alarm: `//${Vue.domains.imgs}/imgs/pole/home/dev-alarm.png`,
      },
      center: {
        bg: `//${Vue.domains.imgs}/imgs/pole/home/pole.png`,
        light: `//${Vue.domains.imgs}/imgs/pole/home/pole-light.png`,
        env: `//${Vue.domains.imgs}/imgs/pole/home/pole-env.png`,
        video: `//${Vue.domains.imgs}/imgs/pole/home/pole-video.png`,
        led: `//${Vue.domains.imgs}/imgs/pole/home/pole-led.png`,
        broad: `//${Vue.domains.imgs}/imgs/pole/home/pole-broad.png`,
        alarm: `//${Vue.domains.imgs}/imgs/pole/home/pole-alarm.png`,
        power: `//${Vue.domains.imgs}/imgs/pole/home/pole-power.png`,
      },
      env: {
        bg: `//${Vue.domains.imgs}/imgs/pole/home/right-top.png`,
        icon: `//${Vue.domains.imgs}/imgs/pole/home/env-icon.png`,
        pm25: `//${Vue.domains.imgs}/imgs/pole/home/env-pm25.png`,
        temp: `//${Vue.domains.imgs}/imgs/pole/home/env-temp.png`,
        hum: `//${Vue.domains.imgs}/imgs/pole/home/env-hum.png`,
        noise: `//${Vue.domains.imgs}/imgs/pole/home/env-noise.png`,
        speed: `//${Vue.domains.imgs}/imgs/pole/home/env-speed.png`,
        direct: `//${Vue.domains.imgs}/imgs/pole/home/env-direct.png`,
        lux: `//${Vue.domains.imgs}/imgs/pole/home/env-lux.png`,
        press: `//${Vue.domains.imgs}/imgs/pole/home/env-press.png`,
        pm10: `//${Vue.domains.imgs}/imgs/pole/home/env-pm10.png`,
      },
      alarm: {
        bg: `//${Vue.domains.imgs}/imgs/pole/home/right-bottom.png`,
        icon: `//${Vue.domains.imgs}/imgs/pole/home/alarm-icon.png`,
      },
      popup: {
        lamppost: {
          voltage: `//${Vue.domains.imgs}/imgs/popup20220720/lamppost/voltage.png`,
          temperature: `//${Vue.domains.imgs}/imgs/popup20220720/lamppost/temperature.png`,
          powerFactor: `//${Vue.domains.imgs}/imgs/popup20220720/lamppost/power-factor.png`,
          power: `//${Vue.domains.imgs}/imgs/popup20220720/lamppost/power.png`,
          lamp: `//${Vue.domains.imgs}/imgs/popup20220720/lamppost/lamp.png`,
          current: `//${Vue.domains.imgs}/imgs/popup20220720/lamppost/current.png`,
          brightness: `//${Vue.domains.imgs}/imgs/popup20220720/lamppost/brightness.png`,
          shutLamp: `//${Vue.domains.imgs}/imgs/popup20220720/lamppost/shutLamp.png`,
          openLamp: `//${Vue.domains.imgs}/imgs/popup20220720/lamppost/openLamp.png`,
        },

      }
    },
    logo: `//${Vue.domains.imgs}/imgs/pole/logo.png`,
    icon: `//${Vue.domains.imgs}/imgs/pole/icon.png`,
    // light: `//${Vue.domains.imgs}/imgs/pole/light.png`,
    online: `//${Vue.domains.imgs}/imgs/pole/online.png`,
    offline: `//${Vue.domains.imgs}/imgs/pole/offline.png`,
    alarm: {
      flag: `//${Vue.domains.imgs}/imgs/pole20220708/flag-speak.png`,
      icon: `//${Vue.domains.imgs}/imgs/pole/alarm.png`,
      center: `//${Vue.domains.imgs}/imgs/pole/alarm-center.png`,
      total: `//${Vue.domains.imgs}/imgs/pole/alarm-total.png`,
      today: `//${Vue.domains.imgs}/imgs/pole/alarm-today.png`,
      last: `//${Vue.domains.imgs}/imgs/pole/alarm-last.png`,
    },
    speak: {
      online: `//${Vue.domains.imgs}/imgs/speak20220727/online.png`,
      offline: `//${Vue.domains.imgs}/imgs/speak20220727/offline.png`,
      alarm: `//${Vue.domains.imgs}/imgs/speak20220727/alarm.png`,
      online2: `//${Vue.domains.imgs}/imgs/speak20220727/online2.png`,
      offline2: `//${Vue.domains.imgs}/imgs/speak20220727/offline2.png`,

      hangUp: `//${Vue.domains.imgs}/imgs/speak20220727/hangUp.png`,
      lookup: `//${Vue.domains.imgs}/imgs/speak20220727/lookup.png`,
      phone: `//${Vue.domains.imgs}/imgs/speak20220727/phone.png`,
      record: `//${Vue.domains.imgs}/imgs/speak20220727/record.png`,
      volume: `//${Vue.domains.imgs}/imgs/speak20220727/volume.png`,

    },
    broad: {
      media: {
        confirm: `//${Vue.domains.imgs}/imgs/pole20220704/media-confirm.png`,
        upload: `//${Vue.domains.imgs}/imgs/pole20220704/media-upload.png`,
        deploy: `//${Vue.domains.imgs}/imgs/pole20220704/media-deploy.png`,
        device: `//${Vue.domains.imgs}/imgs/pole20220704/media-device.png`,
        phone: `//${Vue.domains.imgs}/imgs/pole20220704/media-phone.png`,
      },
      base: {
        icon: `//${Vue.domains.imgs}/imgs/pole/broad.png`,
      }
    },
    wifi: {
      flag: `//${Vue.domains.imgs}/imgs/pole20220708/flag-wifi.png`,
      online: `//${Vue.domains.imgs}/imgs/wifi20220727/online.png`,
      offline: `//${Vue.domains.imgs}/imgs/wifi20220727/offline.png`,
      alarm: `//${Vue.domains.imgs}/imgs/wifi20220727/alarm.png`,
    },
    garbage: {
      flag: `//${Vue.domains.imgs}/imgs/pole20220708/flag-garbage.png`,
      online: `//${Vue.domains.imgs}/imgs/garbage20220727/online.png`,
      offline: `//${Vue.domains.imgs}/imgs/garbage20220727/offline.png`,
      alarm: `//${Vue.domains.imgs}/imgs/garbage20220727/alarm.png`,
      volLess: `//${Vue.domains.imgs}/imgs/garbage20220727/volLess.png`,
    },
    charge: {
      flag: `//${Vue.domains.imgs}/imgs/pole20220708/flag-garbage.png`,
      online: `//${Vue.domains.imgs}/imgs/charge20220727/online.png`,
      offline: `//${Vue.domains.imgs}/imgs/charge20220727/offline.png`,
      alarm: `//${Vue.domains.imgs}/imgs/charge20220727/alarm.png`,
      // volLess: `//${Vue.domains.imgs}/imgs/garbage20220727/volLess.png`,
    },
    lock: {
      flag: `//${Vue.domains.imgs}/imgs/pole20220708/flag-lock.png`,
    },
    manhole: {
      flag: `//${Vue.domains.imgs}/imgs/pole20220708/flag-manhole.png`,
      online: `//${Vue.domains.imgs}/imgs/manhole20220726/online.png`,
      offline: `//${Vue.domains.imgs}/imgs/manhole20220726/offline.png`,
      volLess: `//${Vue.domains.imgs}/imgs/manhole20220726/vol-less.png`,
      location: `//${Vue.domains.imgs}/imgs/manhole20220726/location.png`,
    },
    phone: {
      flag: `//${Vue.domains.imgs}/imgs/pole20220708/flag-phone.png`,
      online: `//${Vue.domains.imgs}/imgs/phone20220727/online.png`,
      offline: `//${Vue.domains.imgs}/imgs/phone20220727/offline.png`,
      alarm: `//${Vue.domains.imgs}/imgs/phone20220727/alarm.png`,
      // location: `//${Vue.domains.imgs}/imgs/phone20220727/location.png`,
    },
    env: {
      flag: `//${Vue.domains.imgs}/imgs/pole20220708/temp-icon.png`,
      online: `//${Vue.domains.imgs}/imgs/env20220727/online.png`,
      offline: `//${Vue.domains.imgs}/imgs/env20220727/offline.png`,
      alarm: `//${Vue.domains.imgs}/imgs/env20220727/alarm.png`,
      icon: `//${Vue.domains.imgs}/imgs/pole/env.png`,
      direct: `//${Vue.domains.imgs}/imgs/pole/env-direct.png`,
      hum: `//${Vue.domains.imgs}/imgs/pole/env-hum.png`,
      noise: `//${Vue.domains.imgs}/imgs/pole/env-noise.png`,
      pm10: `//${Vue.domains.imgs}/imgs/pole/env-pm10.png`,
      pm25: `//${Vue.domains.imgs}/imgs/pole/env-pm25.png`,
      speed: `//${Vue.domains.imgs}/imgs/pole/env-speed.png`,
    },
    led: {
      icon: `//${Vue.domains.imgs}/imgs/pole/led.png`,
      play: `//${Vue.domains.imgs}/imgs/pole/led-play.png`,
      wait: `//${Vue.domains.imgs}/imgs/pole/led-wait.png`,
    },
    power: {
      flag: `//${Vue.domains.imgs}/imgs/pole20220708/flag-box.png`,
      icon: `//${Vue.domains.imgs}/imgs/pole/power.png`,
      center: `//${Vue.domains.imgs}/imgs/pole/power-center.png`,
      state: `//${Vue.domains.imgs}/imgs/pole/power-state.png`,
      v: `//${Vue.domains.imgs}/imgs/pole/power-v.png`,
      i: `//${Vue.domains.imgs}/imgs/pole/power-i.png`,
      p: `//${Vue.domains.imgs}/imgs/pole/power-p.png`,
      f: `//${Vue.domains.imgs}/imgs/pole/power-f.png`,
    },
    video: `//${Vue.domains.imgs}/imgs/pole/video.png`,
    powersupply:{
      energize: `//${Vue.domains.imgs}/imgs/powerSupply/energize.png`,
      outage: `//${Vue.domains.imgs}/imgs/powerSupply/outage.png`,
      powerSupply: `//${Vue.domains.imgs}/imgs/powerSupply/powerSupply.png`,
      ins: `//${Vue.domains.imgs}/imgs/powerSupply/ins.png`,
    }
  },
  powerbox: {
    status: {
      online: `//${Vue.domains.imgs}/imgs/status/powerbox/online.png`,
      change: `//${Vue.domains.imgs}/imgs/status/powerbox/change.png`,
      fetal: `//${Vue.domains.imgs}/imgs/status/powerbox/fetal.png`,
      pre: `//${Vue.domains.imgs}/imgs/status/powerbox/pre.png`,
      stop: `//${Vue.domains.imgs}/imgs/status/powerbox/stop.png`,
      alarm: `//${Vue.domains.imgs}/imgs/status/powerbox/alarm.png`,
      offline: `//${Vue.domains.imgs}/imgs/status/powerbox/change.png`,
    },
    status2: {
      online: `//${Vue.domains.imgs}/imgs/status/powerbox2/online.png`,
      offline: `//${Vue.domains.imgs}/imgs/status/powerbox2/offline.png`,
      fetalNo: `//${Vue.domains.imgs}/imgs/status/powerbox2/fetalNo.png`,
      fetal: `//${Vue.domains.imgs}/imgs/status/powerbox2/fetal.png`,
    },
    single: {
      menu: {
        analysis: {
          icon: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/icon.png`,
          bg01: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/bg-01.png`,
          bg02: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/bg-02.png`,
          bg03: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/bg-03.png`,
          bgAlarm: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/bg-alarm.png`,
          iconAlarm: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/icon-alarm.png`,
          iconInfo: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/icon-info.png`,
          iconCurrent: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/icon-current.png`,
          wave01: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/wave-01.png`,
          wave02: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/wave-02.png`,
          wave03: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/wave-03.png`,
          wave04: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/wave-04.png`,
          openTotal: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/open-total.png`,
          openYestoday: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/open-yestoday.png`,
          openFront: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/open-front.png`,
          iconView: `//${Vue.domains.imgs}/imgs/powerbox/single/menu/analysis/icon-view.png`,
        }
      },
      light: {
        offlineOne: `//${Vue.domains.imgs}/imgs/powerbox/single/light/offline-one.png`,
        offlineTwo: `//${Vue.domains.imgs}/imgs/powerbox/single/light/offline-two.png`,
        onlineOffOne: `//${Vue.domains.imgs}/imgs/powerbox/single/light/online-off-one.png`,
        onlineOffTwo: `//${Vue.domains.imgs}/imgs/powerbox/single/light/online-off-two.png`,
        onlineOnOne: `//${Vue.domains.imgs}/imgs/powerbox/single/light/online-on-one.png`,
        onlineOnTwo: `//${Vue.domains.imgs}/imgs/powerbox/single/light/online-on-two.png`,
        onlineOnLeft: `//${Vue.domains.imgs}/imgs/powerbox/single/light/online-on-left.png`,
        onlineOnRight: `//${Vue.domains.imgs}/imgs/powerbox/single/light/online-on-right.png`,
        alarmOffOne: `//${Vue.domains.imgs}/imgs/powerbox/single/light/alarm-off-one.png`,
        alarmOffTwo: `//${Vue.domains.imgs}/imgs/powerbox/single/light/alarm-off-two.png`,
        alarmOnOne: `//${Vue.domains.imgs}/imgs/powerbox/single/light/alarm-on-one.png`,
        alarmOnTwo: `//${Vue.domains.imgs}/imgs/powerbox/single/light/alarm-on-two.png`,
        alarmOnLeft: `//${Vue.domains.imgs}/imgs/powerbox/single/light/alarm-on-left.png`,
        alarmOnRight: `//${Vue.domains.imgs}/imgs/powerbox/single/light/alarm-on-right.png`,
      },
      topLight: {
        offline: `//${Vue.domains.imgs}/imgs/powerbox/single/light/top-offline.png`,
        onlineOff: `//${Vue.domains.imgs}/imgs/powerbox/single/light/top-online-off.png`,
        onlineOn: `//${Vue.domains.imgs}/imgs/powerbox/single/light/top-online-on.png`,
        alarmOff: `//${Vue.domains.imgs}/imgs/powerbox/single/light/top-alarm-off.png`,
        alarmOn: `//${Vue.domains.imgs}/imgs/powerbox/single/light/top-alarm-on.png`,
      },
      config: {
        outputOn: `//${Vue.domains.imgs}/imgs/powerbox/single/config/output-on.png`,
        outputOff: `//${Vue.domains.imgs}/imgs/powerbox/single/config/output-off.png`,
        contactOn: `//${Vue.domains.imgs}/imgs/powerbox/single/config/contact-on.png`,
        contactOff: `//${Vue.domains.imgs}/imgs/powerbox/single/config/contact-off.png`,
        contactFail: `//${Vue.domains.imgs}/imgs/powerbox/single/config/contact-fail.png`,
        contactNoCheck: `//${Vue.domains.imgs}/imgs/powerbox/single/config/contact-noCheck.png`,
      },
      lamp: {
        offline: `//${Vue.domains.imgs}/imgs/powerbox/single/lamp/lamp-offline.png`,
        goOut: `//${Vue.domains.imgs}/imgs/powerbox/single/lamp/lamp-goOut.png`,
        bright: `//${Vue.domains.imgs}/imgs/powerbox/single/lamp/lamp-bright.png`,
      }
    },
    muti: {},
  },
  light: {
    road: {
      tree: {
        root: `//${Vue.domains.imgs}/imgs/tree-20220214/root.png`,
        group: `//${Vue.domains.imgs}/imgs/tree-20220214/group.png`,
        down: `//${Vue.domains.imgs}/imgs/tree-20220214/down.png`,
        up: `//${Vue.domains.imgs}/imgs/tree-20220214/up.png`,
        online: `//${Vue.domains.imgs}/imgs/tree-20220214/pb-online.png`,
        offline: `//${Vue.domains.imgs}/imgs/tree-20220214/pb-offline.png`,
        alarm: `//${Vue.domains.imgs}/imgs/tree-20220214/pb-alarm.png`,
      },
      single: {
        header: {
          bg: `//${Vue.domains.imgs}/imgs/viewer-20220214/header-bg.png`,
          clock: `//${Vue.domains.imgs}/imgs/viewer-20220214/clock.png`,
          linkman: `//${Vue.domains.imgs}/imgs/viewer-20220214/linkman.png`,
          location: `//${Vue.domains.imgs}/imgs/viewer-20220214/location.png`,
          mobile: `//${Vue.domains.imgs}/imgs/viewer-20220214/mobile.png`,
          organ: `//${Vue.domains.imgs}/imgs/viewer-20220214/organ.png`,
          wifi: `//${Vue.domains.imgs}/imgs/viewer-20220214/wifi.png`,
          down: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/down.png`,
          toRight: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/toRight.png`,
          upward: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/upward.png`,
          police: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/police.png`,

          card: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/card.png`,
          personal: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/personal.png`,
          phone: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/phone.png`,
          unit: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/unit.png`,
          coordinate: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/location.png`,
          voltage: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/voltage.png`,
          leakage: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/leakage.png`,

          alarm: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/census-alarm.png`,
          lightUp: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/census-lightUp.png`,
          box: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/census-box.png`,
          online: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/census-online.png`,
          lamp: `//${Vue.domains.imgs}/imgs/viewer-20220214/analyse/lamp.png`,

        },
        viewer: {
          lightRate: `//${Vue.domains.imgs}/imgs/viewer-20220214/light-rate.png`,
          alarmRate: `//${Vue.domains.imgs}/imgs/viewer-20220214/alarm-rate.png`,
          branchIcon: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/branch-icon.png`,
          contactClose: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/contact-close.png`,
          contactOff: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/contact-off.png`,
          contactOn: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/contact-on.png`,
          contactOpen: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/contact-open.png`,
          lightOff: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/light-off.png`,
          lightOn: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/light-on.png`,
          mainIcon: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/main-icon.png`,
          securityIcon: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/security-icon.png`,
          slightOn: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/slight-on.png`,
          box: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/box.png`,

          channelOFF: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/channelOFF.png`,
          channelON: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/channelON.png`,
          supplyOFF: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/supplyOFF.png`,
          supplyON: `//${Vue.domains.imgs}/imgs/viewer-20220214/real/supplyON.png`,
          contactFail: `//${Vue.domains.imgs}/imgs/powerbox/single/config/contact-fail.png`,
          contactNoCheck: `//${Vue.domains.imgs}/imgs/powerbox/single/config/contact-noCheck.png`,
        },
        menu: {
          alarmActive: `//${Vue.domains.imgs}/imgs/viewer-20220214/menu/alarm-active.png`,
          alarmCommon: `//${Vue.domains.imgs}/imgs/viewer-20220214/menu/alarm-common.png`,
          analyseActive: `//${Vue.domains.imgs}/imgs/viewer-20220214/menu/analyse-active.png`,
          analyseCommon: `//${Vue.domains.imgs}/imgs/viewer-20220214/menu/analyse-common.png`,
          cfgActive: `//${Vue.domains.imgs}/imgs/viewer-20220214/menu/cfg-active.png`,
          cfgCommon: `//${Vue.domains.imgs}/imgs/viewer-20220214/menu/cfg-common.png`,
          historyActive: `//${Vue.domains.imgs}/imgs/viewer-20220214/menu/history-active.png`,
          historyCommon: `//${Vue.domains.imgs}/imgs/viewer-20220214/menu/history-common.png`,
          infoActive: `//${Vue.domains.imgs}/imgs/viewer-20220214/menu/info-active.png`,
          infoCommon: `//${Vue.domains.imgs}/imgs/viewer-20220214/menu/info-common.png`,
          police: `//${Vue.domains.imgs}/imgs/viewer-20220214/menu/police-right-lower.png`,
        },
        ctrl: {
          more: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/more.png`,
          capture: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/capture.png`,
          forceRead: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/force-read.png`,
          init: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/init.png`,
          less: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/less.png`,
          open: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/open.png`,
          playVideo: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/play-video.png`,
          readClock: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/read-clock.png`,
          readTime: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/read-time.png`,
          restart: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/restart.png`,
          setPswd: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/set-pswd.png`,
          viewImage: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/view-image.png`,
          viewVideo: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/view-video.png`,
          writeClock: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/write-clock.png`,
          writeTime: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/write-time.png`,
          update: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/update.png`,
          info: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/info.png`,
        },
        lightCtrl: {
          more: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/more.png`,
          less: `//${Vue.domains.imgs}/imgs/viewer-20220214/ctrl/less.png`,
          adjust: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/adjust.png`,
          close: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/close.png`,
          forceRead: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/force-read.png`,
          getAlarm: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/get-alarm.png`,
          getAlarmStatus: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/get-alarm-status.png`,
          getGyro: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/get-gyro.png`,
          getParams: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/get-params.png`,
          getUnion: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/get-union.png`,
          groupAdjust: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/group-adjust.png`,
          info: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/info.png`,
          init: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/init.png`,
          open: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/open.png`,
          readClock: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/read-clock.png`,
          readGroup: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/read-group.png`,
          readNet: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/read-net.png`,
          readSim: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/read-sim.png`,
          readTime1: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/read-time1.png`,
          readTime2: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/read-time2.png`,
          readTimeType: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/read-time-type.png`,
          reset: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/reset.png`,
          setAlarm: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/set-alarm.png`,
          setAlarmStatus: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/set-alarm-status.png`,
          setGroup: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/set-group.png`,
          setGyro: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/set-gyro.png`,
          setNet: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/set-net.png`,
          setParams: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/set-params.png`,
          setTimeType: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/set-time-type.png`,
          setUnion: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/set-union.png`,
          writeClock: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/write-clock.png`,
          writeTime1: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/write-time1.png`,
          writeTime2: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/write-time2.png`,
          update: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/update.png`,
          monitor: `//${Vue.domains.imgs}/imgs/viewer-20220214/light/monitor.png`,
        }
      },
    },
    info: {
      deleteImg: `//${Vue.domains.imgs}/imgs/viewer-20220214/deleteImg.png`,
      enlargeImg: `//${Vue.domains.imgs}/imgs/viewer-20220214/enlargeImg.png`,
      addImg: `//${Vue.domains.imgs}/imgs/viewer-20220214/addImg.png`,
      assetsYearNum: `//${Vue.domains.imgs}/imgs/info/info-assetsYear-num.png`,
      assetsMonthNum: `//${Vue.domains.imgs}/imgs/info/info-assetsMonth-num.png`,
      cableTypeNum: `//${Vue.domains.imgs}/imgs/info/info-cableType-num.png`,
      cableNum: `//${Vue.domains.imgs}/imgs/info/info-cable-num.png`,
      lampNum: `//${Vue.domains.imgs}/imgs/info/info-lamp-num.png`,
      rdLampNum: `//${Vue.domains.imgs}/imgs/info/info-rdLamp-num.png`,
      rdNum: `//${Vue.domains.imgs}/imgs/info/info-rd-num.png`,
      powerBoxNum: `//${Vue.domains.imgs}/imgs/info/info-powerBox-num.png`,
      number: `//${Vue.domains.imgs}/imgs/info/info-number.png`,
      user: `//${Vue.domains.imgs}/imgs/info/info-user.png`,
      unit: `//${Vue.domains.imgs}/imgs/info/info-unit.png`,
      group: `//${Vue.domains.imgs}/imgs/info/info-group.png`,
      powerBox: `//${Vue.domains.imgs}/imgs/info/info-powerBox.png`,

    },
    factory: {
      index: {
        header: {
          user: `//${Vue.domains.imgs}/imgs/light/factory/home/header-user.png`,
          user2: `//${Vue.domains.imgs}/imgs/light/factory/home/header-user2.png`,
          button: {
            common: `//${Vue.domains.imgs}/imgs/light/factory/home/button-common.png`,
            active: `//${Vue.domains.imgs}/imgs/light/factory/home/button-active.png`,
          },
          line: {
            down: `//${Vue.domains.imgs}/imgs/light/factory/home/header-line-down.png`,
            left: `//${Vue.domains.imgs}/imgs/light/factory/home/header-line-left.png`,
            right: `//${Vue.domains.imgs}/imgs/light/factory/home/header-line-right.png`,
          },
        },
      },
      home: {
        bg: `//${Vue.domains.imgs}/imgs/light/factory/home/bg.png`,
        center: {
          pointer1: `//${Vue.domains.imgs}/imgs/light/factory/home/center-pointer-1.png`,
          pointer2: `//${Vue.domains.imgs}/imgs/light/factory/home/center-pointer-2.png`,
          pointer3: `//${Vue.domains.imgs}/imgs/light/factory/home/center-pointer-3.png`,
          pointer4: `//${Vue.domains.imgs}/imgs/light/factory/home/center-pointer-4.png`,
          pointer5: `//${Vue.domains.imgs}/imgs/light/factory/home/center-pointer-5.png`,
        },
        energy: {
          bg: `//${Vue.domains.imgs}/imgs/light/factory/home/energy-bg.png`,
        },
        status: {
          bg: `//${Vue.domains.imgs}/imgs/light/factory/home/status-bg.png`,
        },
        count: {
          light: `//${Vue.domains.imgs}/imgs/light/factory/home/light-count.png`,
          monitor: `//${Vue.domains.imgs}/imgs/light/factory/home/monitor-count.png`,
          power: `//${Vue.domains.imgs}/imgs/light/factory/home/power-count.png`,
        },
        alarm: {
          bg: `//${Vue.domains.imgs}/imgs/light/factory/home/alarm-bg.png`,
          real: {
            bg: `//${Vue.domains.imgs}/imgs/light/factory/home/real-bg.png`,
          },
          power: {
            current: `//${Vue.domains.imgs}/imgs/light/factory/home/alarm-current.png`,
            voltage: `//${Vue.domains.imgs}/imgs/light/factory/home/alarm-voltage.png`,
            door: `//${Vue.domains.imgs}/imgs/light/factory/home/alarm-door.png`,
          },
          light: {
            current: `//${Vue.domains.imgs}/imgs/light/factory/home/alarm-current-light.png`,
            voltage: `//${Vue.domains.imgs}/imgs/light/factory/home/alarm-vol-light.png`,
            offline: `//${Vue.domains.imgs}/imgs/light/factory/home/alarm-offline-light.png`,
          },
        },
      },
      tree: {
        root: `//${Vue.domains.imgs}/imgs/light/factory/power/tree-root.png`,
        node: `//${Vue.domains.imgs}/imgs/light/factory/power/tree-node-common.png`,
        selected: `//${Vue.domains.imgs}/imgs/light/factory/power/tree-node.png`,
      },
      power: {
        boxbg: `//${Vue.domains.imgs}/imgs/light/factory/monitor/power-bg.png`,
        bg: `//${Vue.domains.imgs}/imgs/light/factory/power/power-bg.png`,
        temp: `//${Vue.domains.imgs}/imgs/light/factory/power/power-temp.png`,
        energy: `//${Vue.domains.imgs}/imgs/light/factory/power/power-energy.png`,
        door: {
          open: `//${Vue.domains.imgs}/imgs/light/factory/power/door-open.png`,
          close: `//${Vue.domains.imgs}/imgs/light/factory/power/door-close.png`,
          icon: `//${Vue.domains.imgs}/imgs/light/factory/power/power-door.png`,
        },
        button: {
          bg: `//${Vue.domains.imgs}/imgs/light/factory/power/button-bg.png`,
          hover: `//${Vue.domains.imgs}/imgs/light/factory/power/button-hover.png`,
          forceRead: `//${Vue.domains.imgs}/imgs/light/factory/power/icon-force-read.png`,
          getClock: `//${Vue.domains.imgs}/imgs/light/factory/power/icon-get-clock.png`,
          setClock: `//${Vue.domains.imgs}/imgs/light/factory/power/icon-set-clock.png`,
          handControl: `//${Vue.domains.imgs}/imgs/light/factory/power/icon-hand-control.png`,
          init: `//${Vue.domains.imgs}/imgs/light/factory/power/icon-init.png`,
          bright: `//${Vue.domains.imgs}/imgs/light/factory/power/icon-bright.png`,
        },
        devOps: { //设备运维icon
          devOps1: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps1.png`,
          devOps2: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps2.png`,
          devOps3: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps3.png`,
          devOps4: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps4.png`,
          devOps5: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps5.png`,
          devOps6: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps6.png`,
          devOps7: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps7.png`,
          devOps8: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps8.png`,
          devOps9: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps9.png`,
          devOps10: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps10.png`,
          devOps11: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps11.png`,
          devOps12: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps12.png`,
          devOps13: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps13.png`,
          devOps14: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps14.png`,
          devOps15: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps15.png`,
        },
        devOps2: { //设备运维
          devOps1: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon1.png`,
          devOps2: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon2.png`,
          devOps3: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon3.png`,
          devOps4: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon4.png`,
          devOps5: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon5.png`,
          devOps6: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon6.png`,
          devOps7: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon7.png`,
          devOps8: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon8.png`,
          devOps9: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon9.png`,
          devOps10: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon10.png`,
          devOps11: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon11.png`,
          devOps12: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon12.png`,
          devOps13: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon13.png`,
          devOps14: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon14.png`,
          devOps15: `//${Vue.domains.imgs}/imgs/light/factory/power/devOpsBj0.png`,
          devOps16: `//${Vue.domains.imgs}/imgs/light/factory/power/devOpsBj1.png`,
          // devOps15: `//${Vue.domains.imgs}/imgs/light/factory/power/devOps-icon15.png`,
        },
      },
      light: {
        bg: `//${Vue.domains.imgs}/imgs/light/factory/power/light-bg.png`,
        box: `//${Vue.domains.imgs}/imgs/light/factory/power/light-box.png`,
        status: {
          on: `//${Vue.domains.imgs}/imgs/light/factory/power/light-on.png`,
          off: `//${Vue.domains.imgs}/imgs/light/factory/power/light-off.png`,
        },
        level: {
          100: `//${Vue.domains.imgs}/imgs/light/factory/power/bright-level-100.png`,
          80: `//${Vue.domains.imgs}/imgs/light/factory/power/bright-level-80.png`,
        },
        bright: {
          in: `//${Vue.domains.imgs}/imgs/light/factory/power/bright-in.png`,
          out: `//${Vue.domains.imgs}/imgs/light/factory/power/bright-out.png`,
          temp: `//${Vue.domains.imgs}/imgs/light/factory/power/room-temp.png`,
        },
        lampList: {
          but1: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but1.png`,
          but2: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but2.png`,
          but3: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but3.png`,
          but4: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but4.png`,
          but5: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but5.png`,
          but6: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but6.png`,
          but7: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but7.png`,
          but8: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but8.png`,
          but9: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but9.png`,
          but10: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but10.png`,
          but11: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but11.png`,
          but12: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but12.png`,
          but13: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but13.png`,
          but14: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but14.png`,
          but15: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-but15.png`,
          down: `//${Vue.domains.imgs}/imgs/light/factory/power/lamp-list-down.png`,
        }
      },
      monitor: {
        adjust: {
          cleverControlBg: `//${Vue.domains.imgs}/imgs/light/factory/monitor/clever-control-bg.png`,
          monitorBg: `//${Vue.domains.imgs}/imgs/light/factory/monitor/monitor-bg.png`,
          titleBg: `//${Vue.domains.imgs}/imgs/light/factory/monitor/title-bg.png`,
          areaIcon: `//${Vue.domains.imgs}/imgs/light/factory/monitor/area-icon.png`,
          areaBg: `//${Vue.domains.imgs}/imgs/light/factory/monitor/area-bg.png`,
          areaBgActive: `//${Vue.domains.imgs}/imgs/light/factory/monitor/area-bgActive.png`,
          areaImg: `//${Vue.domains.imgs}/imgs/light/factory/monitor/area-img.png`,
          patternBg: `//${Vue.domains.imgs}/imgs/light/factory/monitor/pattern-bg.png`,
          lt0: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lt0.png`,
          lt50: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lt50.png`,
          lt20: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lt20.png`,
          lt40: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lt40.png`,
          lt60: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lt60.png`,
          lt80: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lt80.png`,
          lt90: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lt90.png`,
          lt100: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lt100.png`,
          dimming: `//${Vue.domains.imgs}/imgs/light/factory/monitor/dimming.png`,
          lamp: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lamp.png`,
          evening: `//${Vue.domains.imgs}/imgs/light/factory/monitor/evening.png`,
          percentage: `//${Vue.domains.imgs}/imgs/light/factory/monitor/percentage.png`,
          rest: `//${Vue.domains.imgs}/imgs/light/factory/monitor/rest.png`,
          computer: `//${Vue.domains.imgs}/imgs/light/factory/monitor/computer.png`,
          computer2: `//${Vue.domains.imgs}/imgs/light/factory/monitor/computer2.png`,
          shadow: `//${Vue.domains.imgs}/imgs/light/factory/monitor/shadow.png`,
          model: `//${Vue.domains.imgs}/imgs/light/factory/monitor/model.png`,
          modelBg: `//${Vue.domains.imgs}/imgs/light/factory/monitor/modelBg.png`,
          brightness: `//${Vue.domains.imgs}/imgs/light/factory/monitor/brightness.png`,
          region: `//${Vue.domains.imgs}/imgs/light/factory/monitor/region.png`,

          lamp100: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lamp100.png`,
          lamp90: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lamp90.png`,
          lamp80: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lamp80.png`,
          lamp60: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lamp60.png`,
          lamp50: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lamp50.png`,
          lamp40: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lamp40.png`,
          lamp20: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lamp20.png`,
          lamp0: `//${Vue.domains.imgs}/imgs/light/factory/monitor/lamp0.png`,

        },
        switch: {
          open: `//${Vue.domains.imgs}/imgs/light/factory/monitor/open.png`,
          close: `//${Vue.domains.imgs}/imgs/light/factory/monitor/close.png`,
        },
        form: {
          close: `//${Vue.domains.imgs}/imgs/light/factory/monitor/form-close.png`,
          close2: `//${Vue.domains.imgs}/imgs/light/factory/monitor/form-close2.png`,
        },

      },
      spread: {
        groupPos: `//${Vue.domains.imgs}/imgs/light/factory/spread/group-pos.png`,
        box: `//${Vue.domains.imgs}/imgs/light/factory/spread/box.png`,
      },
    },
    carat: { //中兴克拉
      navTop: {
        dropDown: `//${Vue.domains.imgs}/imgs/factory20221213/navTop/drop-down.png`,
        checkBg: `//${Vue.domains.imgs}/imgs/factory20221213/navTop/checkBg.png`,
        head: `//${Vue.domains.imgs}/imgs/factory20221213/navTop/head.png`,
      },
      home: {
        arrow2: `//${Vue.domains.imgs}/imgs/factory20221213/home/arrow2.png`,
        arrow: `//${Vue.domains.imgs}/imgs/factory20221213/home/arrow.png`,
        unit: `//${Vue.domains.imgs}/imgs/factory20221213/home/unit.png`,
        lamp: `//${Vue.domains.imgs}/imgs/factory20221213/home/lamp.png`,
        lamppost: `//${Vue.domains.imgs}/imgs/factory20221213/home/lamppost.png`,
        homeLeft: `//${Vue.domains.imgs}/imgs/factory20221213/home/homeLeft.png`,
        homeRight: `//${Vue.domains.imgs}/imgs/factory20221213/home/homeRight.png`,
        gateway: `//${Vue.domains.imgs}/imgs/factory20221213/home/gateway.png`,

        Alerts: `//${Vue.domains.imgs}/imgs/carat/home/Alerts.png`,
        TimeAlerts: `//${Vue.domains.imgs}/imgs/carat/home/TimeAlerts.png`,
        streetLamp: `//${Vue.domains.imgs}/imgs/carat/home/streetLamp.png`,
        Statistics: `//${Vue.domains.imgs}/imgs/carat/home/Statistics.png`,
        energy: `//${Vue.domains.imgs}/imgs/carat/home/energy.png`,

        caratBg: `//${Vue.domains.imgs}/imgs/carat/home/caratBg.png`,

        police3: `//${Vue.domains.imgs}/imgs/carat/home/police3.png`,
        police2: `//${Vue.domains.imgs}/imgs/carat/home/police2.png`,
        police1: `//${Vue.domains.imgs}/imgs/carat/home/police1.png`,

      },
      gis: {
        LightsPolice: `//${Vue.domains.imgs}/imgs/carat/gis/LightsPolice.png`,
        LightsOut: `//${Vue.domains.imgs}/imgs/carat/gis/LightsOut.png`,
        LightsOn: `//${Vue.domains.imgs}/imgs/carat/gis/LightsOn.png`,
        LightsLine: `//${Vue.domains.imgs}/imgs/carat/gis/LightsLine.png`,
        LightsOnLine: `//${Vue.domains.imgs}/imgs/carat/gis/LightsOnLine.png`,
        LightsOutPolice: `//${Vue.domains.imgs}/imgs/carat/gis/LightsOutPolice.png`,

        police: `//${Vue.domains.imgs}/imgs/carat/gis/police.png`,
        policeActive: `//${Vue.domains.imgs}/imgs/carat/gis/policeActive.png`,
        offLine: `//${Vue.domains.imgs}/imgs/carat/gis/offLine.png`,
        offLineActive: `//${Vue.domains.imgs}/imgs/carat/gis/offLineActive.png`,
        statusOn: `//${Vue.domains.imgs}/imgs/carat/gis/statusOn.png`,
        statusOnActive: `//${Vue.domains.imgs}/imgs/carat/gis/statusOnActive.png`,
        statusOut: `//${Vue.domains.imgs}/imgs/carat/gis/statusOut.png`,
        statusOutActive: `//${Vue.domains.imgs}/imgs/carat/gis/statusOutActive.png`,

        butCheck: `//${Vue.domains.imgs}/imgs/carat/gis/butCheck.png`,
        butClose: `//${Vue.domains.imgs}/imgs/carat/gis/butClose.png`,
        butData: `//${Vue.domains.imgs}/imgs/carat/gis/butData.png`,
        butDimming: `//${Vue.domains.imgs}/imgs/carat/gis/butDimming.png`,
        butOpen: `//${Vue.domains.imgs}/imgs/carat/gis/butOpen.png`,
        butSet: `//${Vue.domains.imgs}/imgs/carat/gis/butSet.png`,

        poleBright: `//${Vue.domains.imgs}/imgs/carat/gis/poleBright.png`,
        poleBrightpolice: `//${Vue.domains.imgs}/imgs/carat/gis/poleBrightpolice.png`,
        poleLine: `//${Vue.domains.imgs}/imgs/carat/gis/poleLine.png`,
        poleOut: `//${Vue.domains.imgs}/imgs/carat/gis/poleOut.png`,
        poleOutPolice: `//${Vue.domains.imgs}/imgs/carat/gis/poleOutPolice.png`,
        policePosition: `//${Vue.domains.imgs}/imgs/carat/gis/policePosition.png`,
        closeIcon: `//${Vue.domains.imgs}/imgs/carat/gis/closeIcon.png`,

      },
      lamp: {
        dimming: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/dimming.png`,
        dimmingActive: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/dimming-active.png`,
        scene: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/scene.png`,
        sceneActive: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/scene-active.png`,
        real: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/real.png`,
        realActive: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/real-active.png`,
        linkage: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/linkage.png`,
        linkageActive: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/linkage-active.png`,
        brightness: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/brightness.png`,
        colorTemp: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/colorTemp.png`,
        lamp0: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/lamp0.png`,
        lamp20: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/lamp20.png`,
        lamp40: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/lamp40.png`,
        lamp50: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/lamp50.png`,
        lamp60: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/lamp60.png`,
        lamp80: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/lamp80.png`,
        lamp90: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/lamp90.png`,
        lamp100: `//${Vue.domains.imgs}/imgs/factory20221213/lamp/lamp100.png`,
      }
    },
    status: {
      online: `//${Vue.domains.imgs}/imgs/status/light/online.png`,
      change: `//${Vue.domains.imgs}/imgs/status/light/change.png`,
      fetal: `//${Vue.domains.imgs}/imgs/status/light/fetal.png`,
      pre: `//${Vue.domains.imgs}/imgs/status/light/pre.png`,
      stop: `//${Vue.domains.imgs}/imgs/status/light/stop.png`,
      alarm: `//${Vue.domains.imgs}/imgs/status/light/alarm.png`,
      offline: `//${Vue.domains.imgs}/imgs/status/light/offline.png`,
    },
    map: {
      button: {
        Issued: `//${Vue.domains.imgs}/imgs/gis/button/Issued.png`,
        control: `//${Vue.domains.imgs}/imgs/gis/button/control.png`,
        correct: `//${Vue.domains.imgs}/imgs/gis/button/correct.png`,
        offLight: `//${Vue.domains.imgs}/imgs/gis/button/offLight.png`,
        onLight: `//${Vue.domains.imgs}/imgs/gis/button/onLight.png`,
        read: `//${Vue.domains.imgs}/imgs/gis/button/read.png`,
        survey: `//${Vue.domains.imgs}/imgs/gis/button/survey.png`,
        switch: `//${Vue.domains.imgs}/imgs/gis/button/switch.png`,
        time: `//${Vue.domains.imgs}/imgs/gis/button/time.png`,
      },
      twoPole: `//${Vue.domains.imgs}/imgs/status/map/light/twoPole.png`,
      onePole: `//${Vue.domains.imgs}/imgs/status/map/light/onePole.png`,
      online: `//${Vue.domains.imgs}/imgs/status/map/light/online.png`,
      offline: `//${Vue.domains.imgs}/imgs/status/map/light/offline.png`,
      lightOut: `//${Vue.domains.imgs}/imgs/status/map/light/lightOut.png`,
      light: `//${Vue.domains.imgs}/imgs/status/map/light/light.png`,
      alarm: `//${Vue.domains.imgs}/imgs/status/map/light/alarm.png`,
      state: {
        twoOnline: `//${Vue.domains.imgs}/imgs/status/map/state/twoOnline.png`,
        twoOffline: `//${Vue.domains.imgs}/imgs/status/map/state/twoOffline.png`,
        twoAlarm: `//${Vue.domains.imgs}/imgs/status/map/state/twoAlarm.png`,
        oneOnline: `//${Vue.domains.imgs}/imgs/status/map/state/oneOnline.png`,
        oneOffline: `//${Vue.domains.imgs}/imgs/status/map/state/oneOffline.png`,
        oneAlarm: `//${Vue.domains.imgs}/imgs/status/map/state/oneAlarm.png`,
      }
    },
    wifi: {
      crowd1: `//${Vue.domains.imgs}/imgs/status/map/wifi/crowd1.png`,
      crowd2: `//${Vue.domains.imgs}/imgs/status/map/wifi/crowd2.png`,
      crowd3: `//${Vue.domains.imgs}/imgs/status/map/wifi/crowd3.png`,
      crowd4: `//${Vue.domains.imgs}/imgs/status/map/wifi/crowd4.png`,
    },
    police: {
      see: `//${Vue.domains.imgs}/imgs/status/map/police/see.png`,
      screenshot: `//${Vue.domains.imgs}/imgs/status/map/police/screenshot.png`,
      phone: `//${Vue.domains.imgs}/imgs/status/map/police/phone.png`,
      ellipse: `//${Vue.domains.imgs}/imgs/status/map/police/ellipse.png`,
      boxPopup: `//${Vue.domains.imgs}/imgs/popup20220720/police/policeBoxPopup.png`,
      boxPopupActive: `//${Vue.domains.imgs}/imgs/popup20220720/police/policeBoxPopupActive.png`,
      lampPopup: `//${Vue.domains.imgs}/imgs/popup20220720/police/policeLampPopup.png`,
      lampPopupActive: `//${Vue.domains.imgs}/imgs/popup20220720/police/policeLampPopupActive.png`,
      lampOffline: `//${Vue.domains.imgs}/imgs/popup20220720/police/lampOffline.png`,
      lampOfflineActive: `//${Vue.domains.imgs}/imgs/popup20220720/police/lampOfflineActive.png`,
      lampClock: `//${Vue.domains.imgs}/imgs/popup20220720/police/lampClock.png`,
      lampClockActive: `//${Vue.domains.imgs}/imgs/popup20220720/police/lampClockActive.png`,
    },

  },
  dataAnalysis: {
    lightning: `//${Vue.domains.imgs}/imgs/dataAnalysis/found20230131/lightning.png`,
  },
  assetManage: {
    AssetInfoActive: `//${Vue.domains.imgs}/imgs/assetManage/found20230202/AssetInfoActive.png`,
    AssetInfo: `//${Vue.domains.imgs}/imgs/assetManage/found20230202/AssetInfo.png`,
    AssetChangeActive: `//${Vue.domains.imgs}/imgs/assetManage/found20230202/AssetChangeActive.png`,
    AssetChange: `//${Vue.domains.imgs}/imgs/assetManage/found20230202/AssetChange.png`,
    PowerBoxActive: `//${Vue.domains.imgs}/imgs/assetManage/found20230202/PowerBoxActive.png`,
    PowerBox: `//${Vue.domains.imgs}/imgs/assetManage/found20230202/PowerBox.png`,
    otherAssetsActive: `//${Vue.domains.imgs}/imgs/assetManage/found20230202/otherAssetsActive.png`,
    otherAssets: `//${Vue.domains.imgs}/imgs/assetManage/found20230202/otherAssets.png`,
    infoActive: `//${Vue.domains.imgs}/imgs/assetManage/found20230202/infoActive.png`,
    info: `//${Vue.domains.imgs}/imgs/assetManage/found20230202/info.png`,
  },
  mochaITOM: { //运维管理
    manageUnit: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/manageUnit.png`,
    manageUnitActive: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/manageUnitActive.png`,
    myJob: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/myJob.png`,
    myJobActive: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/myJobActive.png`,
    raiseUnit: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/raiseUnit.png`,
    raiseUnitActive: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/raiseUnitActive.png`,
    workCensus: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/workCensus.png`,
    workCensusActie: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/workCensusActie.png`,
    workDispose: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/workDispose.png`,
    workDisposeActive: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/workDisposeActive.png`,
    workManage: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/workManage.png`,
    workManageActie: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230210/workManageActie.png`,
    uploadImg: `//${Vue.domains.imgs}/imgs/mochaITOM/found20230217/uploadImg.png`,
  },
  bigMap: {
    defaultMap: `//${Vue.domains.imgs}/imgs/map/defaultMap.png`,
  },
  novice: { //新手引导
    treeIcon: `//${Vue.domains.imgs}/imgs/novice/treeIcon.png`,
    treeIcon2: `//${Vue.domains.imgs}/imgs/novice/treeIcon2.jpg`,
    treeIcon3: `//${Vue.domains.imgs}/imgs/novice/treeIcon3.jpg`,
    lightAllIcon: `//${Vue.domains.imgs}/imgs/novice/lightAllIcon.png`,
  },
  headerUserIcon: `//${Vue.domains.imgs}/imgs/header/header-user.png`,
  headerUserIcon2: `//${Vue.domains.imgs}/imgs/header/header-user2.png`,
  headerUserDown: `//${Vue.domains.imgs}/imgs/header/header-user-down.png`,
  headerLineLeft: `//${Vue.domains.imgs}/imgs/header/header-line-left.png`,
  headerLineRight: `//${Vue.domains.imgs}/imgs/header/header-line-right.png`,
  headerCenter: `//${Vue.domains.imgs}/imgs/header/header-center.png`,
  headerCenterManhole: `//${Vue.domains.imgs}/imgs/header/header-center-manhole.png`,
  headerCenterLed: `//${Vue.domains.imgs}/imgs/header/header-center-led.png`,
  headerCenterEnv: `//${Vue.domains.imgs}/imgs/header/header-center-env.png`,
  headerCenterVideo: `//${Vue.domains.imgs}/imgs/header/header-center-video.png`,
  headerCenterPole: `//${Vue.domains.imgs}/imgs/header/header-center-pole.png`,
  headerButtonItem: `//${Vue.domains.imgs}/imgs/header/header-button-item.png`,
  headerButtonSelect: `//${Vue.domains.imgs}/imgs/header/header_button_select.png`,

  headerBg: `//${Vue.domains.imgs}/imgs/header/headerBg.png`,
  headerButtonActive: `//${Vue.domains.imgs}/imgs/header/header-active.png`,

  analyseTitle: `//${Vue.domains.imgs}/imgs/base/analyse_title.png`,
  ctrlTitle: `//${Vue.domains.imgs}/imgs/base/ctrl_title.png`,
  asset: `//${Vue.domains.imgs}/imgs/base/asset.png`,
  box: `//${Vue.domains.imgs}/imgs/base/box.png`,
  device: `//${Vue.domains.imgs}/imgs/base/device.png`,
  map: `//${Vue.domains.imgs}/imgs/base/map.png`,
  photo: `//${Vue.domains.imgs}/imgs/base/photo.png`,
  sim: `//${Vue.domains.imgs}/imgs/base/sim.png`,
  pos: `//${Vue.domains.imgs}/imgs/common/pos.png`,
  user: `//${Vue.domains.imgs}/imgs/common/user.png`,
  owner: `//${Vue.domains.imgs}/imgs/common/owner.png`,
  mobile: `//${Vue.domains.imgs}/imgs/common/mobile.png`,
  clock: `//${Vue.domains.imgs}/imgs/common/clock.png`,
  analysis: `//${Vue.domains.imgs}/imgs/button/analysis.png`,
  alarm: `//${Vue.domains.imgs}/imgs/button/alarm.png`,
  ctrl: `//${Vue.domains.imgs}/imgs/button/ctrl.png`,
  base: `//${Vue.domains.imgs}/imgs/button/base.png`,
  configTitle: `//${Vue.domains.imgs}/imgs/config/config-title.png`,
  baseTitle: `//${Vue.domains.imgs}/imgs/base/base_title.png`,
  forceRead: `//${Vue.domains.imgs}/imgs/config/force-read.png`,
  getClock: `//${Vue.domains.imgs}/imgs/config/get-clock.png`,
  getTime: `//${Vue.domains.imgs}/imgs/config/get-time.png`,
  handClose: `//${Vue.domains.imgs}/imgs/config/hand-close.png`,
  handOpen: `//${Vue.domains.imgs}/imgs/config/hand-open.png`,
  openLock: `//${Vue.domains.imgs}/imgs/config/open-lock.png`,
  powerContact: `//${Vue.domains.imgs}/imgs/config/power-contact.png`,
  powerControl: `//${Vue.domains.imgs}/imgs/config/power-control.png`,
  powerMainOut: `//${Vue.domains.imgs}/imgs/config/power-main-out.png`,
  powerMainOn: `//${Vue.domains.imgs}/imgs/config/power-main-on.png`,
  powerMainOff: `//${Vue.domains.imgs}/imgs/config/power-main-off.png`,
  roadLightOn: `//${Vue.domains.imgs}/imgs/config/road-light-on.png`,
  roadLightOff: `//${Vue.domains.imgs}/imgs/config/road-light-off.png`,
  setClock: `//${Vue.domains.imgs}/imgs/config/set-clock.png`,
  setTime: `//${Vue.domains.imgs}/imgs/config/set-time.png`,
  switchHand: `//${Vue.domains.imgs}/imgs/config/switch-hand.png`,
  switchStop: `//${Vue.domains.imgs}/imgs/config/switch-stop.png`,
  switchRemote: `//${Vue.domains.imgs}/imgs/config/switch-remote.png`,
  switchTime: `//${Vue.domains.imgs}/imgs/config/switch-time.png`,
  iconPowerMain: `//${Vue.domains.imgs}/imgs/status/icon-power-main.png`,
  iconPowerSecurity: `//${Vue.domains.imgs}/imgs/status/icon-power-security.png`,
  btnControlBlue: `//${Vue.domains.imgs}/imgs/config/control-button-blue.png`,
  btnControlYellow: `//${Vue.domains.imgs}/imgs/config/control-button-yellow.png`,
  btnControlStatus: `//${Vue.domains.imgs}/imgs/config/control-status.png`,
  btnControlCapture: `//${Vue.domains.imgs}/imgs/control/capture.png`,
  btnControlClose: `//${Vue.domains.imgs}/imgs/control/close.png`,
  btnControlInit: `//${Vue.domains.imgs}/imgs/control/init.png`,
  btnControlOpen: `//${Vue.domains.imgs}/imgs/control/open.png`,
  btnControlPicture: `//${Vue.domains.imgs}/imgs/control/picture.png`,
  btnControlPlay: `//${Vue.domains.imgs}/imgs/control/play.png`,
  btnControlPswd: `//${Vue.domains.imgs}/imgs/control/pswd.png`,
  btnControlReset: `//${Vue.domains.imgs}/imgs/control/reset.png`,
  btnControlSearch: `//${Vue.domains.imgs}/imgs/control/search.png`,
  btnControlSetClock: `//${Vue.domains.imgs}/imgs/control/set-clock.png`,
  btnControlGetClock: `//${Vue.domains.imgs}/imgs/control/set-clock.png`,
  btnControlVideo: `//${Vue.domains.imgs}/imgs/control/video.png`,
  btnOutputOpen: `//${Vue.domains.imgs}/imgs/control/control-open.png`,
  btnOutputClose: `//${Vue.domains.imgs}/imgs/control/control-close.png`,
  btnGetClock: `//${Vue.domains.imgs}/imgs/base/getclock_32X32.png`,
  btnSetClock: `//${Vue.domains.imgs}/imgs/base/setclock_32X32.png`,
  btnForceRead: `//${Vue.domains.imgs}/imgs/base/forceRead_32X32.png`,
  statusSingleLevel: `//${Vue.domains.imgs}/imgs/status/single-level.png`,
  statusTitleAp: `//${Vue.domains.imgs}/imgs/status/status_title_ap.png`,
  statusTitleC: `//${Vue.domains.imgs}/imgs/status/status_title_c.png`,
  statusTitleEnergy: `//${Vue.domains.imgs}/imgs/status/status_title_energy.png`,
  statusTitleF: `//${Vue.domains.imgs}/imgs/status/status_title_f.png`,
  statusTitleLightRate: `//${Vue.domains.imgs}/imgs/status/status_title_lightrate.png`,
  statusTitleMaxp: `//${Vue.domains.imgs}/imgs/status/status_title_maxp.png`,
  statusTitlePayload: `//${Vue.domains.imgs}/imgs/status/status_title_payload.png`,
  statusTitleQ: `//${Vue.domains.imgs}/imgs/status/status_title_q.png`,
  statusTitleRp: `//${Vue.domains.imgs}/imgs/status/status_title_rp.png`,
  statusTitleV: `//${Vue.domains.imgs}/imgs/status/status_title_v.png`,
  statusTitleDoor: `//${Vue.domains.imgs}/imgs/status/status_title_door.png`,
  statusTitleWater: `//${Vue.domains.imgs}/imgs/status/status_title_water.png`,
  statusTitleSwitch: `//${Vue.domains.imgs}/imgs/status/status_title_switch.png`,
  statusTitleSmoke: `//${Vue.domains.imgs}/imgs/status/status_title_smoke.png`,
  statusTitleLock: `//${Vue.domains.imgs}/imgs/status/status_title_lock.png`,
  statusTitleBranch: `//${Vue.domains.imgs}/imgs/status/status_title_branch.png`,
  statusHeaderBranch: `//${Vue.domains.imgs}/imgs/status/status_header_branch.png`,
  statusBackground: `//${Vue.domains.imgs}/imgs/status/status_bg.png`,
  treeCollectImg: `//${Vue.domains.imgs}/imgs/tree/collect.png`,
  treeExpandImg: `//${Vue.domains.imgs}/imgs/tree/expand.png`,
  treeSearchImg: `//${Vue.domains.imgs}/imgs/tree/tree-search.png`,
  treeGroupAdd: `//${Vue.domains.imgs}/imgs/tree/tree-add-group.png`,
  treeGroupEdit: `//${Vue.domains.imgs}/imgs/tree/tree-edit-group.png`,
  treeGroupDel: `//${Vue.domains.imgs}/imgs/tree/tree-del-group.png`,
  treeDeviceAdd: `//${Vue.domains.imgs}/imgs/tree/tree-add-dev.png`,
  treePowerOffline: `//${Vue.domains.imgs}/imgs/tree/tree_offline.png`,
  treePowerOnline: `//${Vue.domains.imgs}/imgs/tree/tree_online.png`,
  treePowerAlarm: `//${Vue.domains.imgs}/imgs/tree/tree_alarm.png`,
  // treeGroupSelect: `//${Vue.domains.imgs}/imgs/tree/group_select.png`,
  // treeGroup: `//${Vue.domains.imgs}/imgs/tree/group.png`,
  // treeRoot: `//${Vue.domains.imgs}/imgs/tree/root.png`,
  mapPowerOffline: `//${Vue.domains.imgs}/imgs/map/power_offline.png`,
  mapPowerOnline: `//${Vue.domains.imgs}/imgs/map/power_working.png`,
  mapPowerAlarm: `//${Vue.domains.imgs}/imgs/map/power_warning.png`,
  mapPowerRepair: `//${Vue.domains.imgs}/imgs/map/power_repair.png`,
  mapPowerError: `//${Vue.domains.imgs}/imgs/map/power_error.png`,
  mapPowerBlue: `//${Vue.domains.imgs}/imgs/map/power_blue.png`,
  mapLightOffline: `//${Vue.domains.imgs}/imgs/map/light_offline.png`,
  mapLightOnline: `//${Vue.domains.imgs}/imgs/map/light_working.png`,
  mapLightAlarm: `//${Vue.domains.imgs}/imgs/map/light_warning.png`,
  mapLightError: `//${Vue.domains.imgs}/imgs/map/light_error.png`,
  mapLightBlue: `//${Vue.domains.imgs}/imgs/map/light_blue.png`,
  mapInfoClose: `//${Vue.domains.imgs}/imgs/map/map_info_close.png`,
  mapAlarmTitle: `//${Vue.domains.imgs}/imgs/map/map_alarm_title.png`,
  mapAlarmItem: `//${Vue.domains.imgs}/imgs/map/map_alarm_item.png`,
  mapAnalyseTitle: `//${Vue.domains.imgs}/imgs/map/map_analyse_title.png`,
  menuMutiBase: `//${Vue.domains.imgs}/imgs/menu/muti-base.png`,
  menuMutiControl: `//${Vue.domains.imgs}/imgs/menu/muti-control.png`,
  menuMutiSearch: `//${Vue.domains.imgs}/imgs/menu/muti-search.png`,
  menuMutiGis: `//${Vue.domains.imgs}/imgs/menu/muti-gis.png`,
  menuMutiBaseActive: `//${Vue.domains.imgs}/imgs/menu/muti-base-active.png`,
  menuMutiControlActive: `//${Vue.domains.imgs}/imgs/menu/muti-control-active.png`,
  menuMutiSearchActive: `//${Vue.domains.imgs}/imgs/menu/muti-search-active.png`,
  menuMutiGisActive: `//${Vue.domains.imgs}/imgs/menu/muti-gis-active.png`,
  menuMutiBaseOnline: `//${Vue.domains.imgs}/imgs/muti/base/online.png`,
  menuMutiBasePowerBox: `//${Vue.domains.imgs}/imgs/muti/base/power-box.png`,
  menuMutiBaseViewItem: `//${Vue.domains.imgs}/imgs/muti/base/view-item.png`,
  menuMutiBaseAlarm: `//${Vue.domains.imgs}/imgs/muti/base/alarm.png`,
  menuMutiBaseIconStatus: `//${Vue.domains.imgs}/imgs/muti/base/icon-status.png`,
  menuMutiBaseLightOpen: `//${Vue.domains.imgs}/imgs/muti/base/light-open.png`,
  mutiCtrlIcon: `//${Vue.domains.imgs}/imgs/muti/ctrl/icon-ctrl.png`,
  mutiCtrlForceRead: `//${Vue.domains.imgs}/imgs/muti/ctrl/force-read.png`,
  mutiCtrlClock: `//${Vue.domains.imgs}/imgs/muti/ctrl/clock.png`,
  mutiCtrlTime: `//${Vue.domains.imgs}/imgs/muti/ctrl/time.png`,
  mutiCtrlTemp: `//${Vue.domains.imgs}/imgs/muti/ctrl/temp.png`,
  mutiSearchHistory: `//${Vue.domains.imgs}/imgs/muti/search/history.png`,
  mutiSearchOnline: `//${Vue.domains.imgs}/imgs/muti/search/online.png`,
  mutiSearchStation: `//${Vue.domains.imgs}/imgs/muti/search/station.png`,
  mutiSearchAlarmCurrent: `//${Vue.domains.imgs}/imgs/muti/search/alarm-current.png`,
  mutiSearchAlarmLog: `//${Vue.domains.imgs}/imgs/muti/search/alarm-log.png`,
  mutiSearchCtrlHistory: `//${Vue.domains.imgs}/imgs/muti/search/ctrl-history.png`,
  mutiSearchFireTime: `//${Vue.domains.imgs}/imgs/muti/search/fire-time.png`,
  lightMainLineCount: `//${Vue.domains.imgs}/imgs/light/main/line-count.png`,
  lightMainLocation: `//${Vue.domains.imgs}/imgs/light/main/location.png`,
  lightMainOffline: `//${Vue.domains.imgs}/imgs/light/main/offline.png`,
  lightMainOpenTime: `//${Vue.domains.imgs}/imgs/light/main/open-time.png`,
  lightMainPoint: `//${Vue.domains.imgs}/imgs/light/main/point.png`,
  lightMainPoleCount: `//${Vue.domains.imgs}/imgs/light/main/pole-count.png`,
  lightMainRunning: `//${Vue.domains.imgs}/imgs/light/main/running.png`,
  lightMainStop: `//${Vue.domains.imgs}/imgs/light/main/stop.png`,
  lightMainSunDown: `//${Vue.domains.imgs}/imgs/light/main/sun-down.png`,
  lightMainSunRise: `//${Vue.domains.imgs}/imgs/light/main/sun-rise.png`,
  lightMainBorder: `//${Vue.domains.imgs}/imgs/light/main/border.png`,
  lightMainBorderRightTop: `//${Vue.domains.imgs}/imgs/light/main/border-right-top.png`,
  lightMainBorderCenterBottom: `//${Vue.domains.imgs}/imgs/light/main/border-center-bottom.png`,
  lightMainBorderLeftBottom: `//${Vue.domains.imgs}/imgs/light/main/border-left-bottom.png`,
  lightMainBorderLeftMiddle: `//${Vue.domains.imgs}/imgs/light/main/border-left-middle.png`,
  lightMainBorderRightBottom: `//${Vue.domains.imgs}/imgs/light/main/border-right-bottom.png`,
  lightMainBoxCount: `//${Vue.domains.imgs}/imgs/light/main/box-count.png`,
  lightMainChange: `//${Vue.domains.imgs}/imgs/light/main/change.png`,
  lightMainClock: `//${Vue.domains.imgs}/imgs/light/main/clock.png`,
  lightMainCloseTime: `//${Vue.domains.imgs}/imgs/light/main/close-time.png`,
  lightMainCloud: `//${Vue.domains.imgs}/imgs/light/main/cloud.png`,
  lightMainFlag: `//${Vue.domains.imgs}/imgs/light/main/flag.png`,
  lightMainLightCount: `//${Vue.domains.imgs}/imgs/light/main/light-count.png`,
  lightMainLightMain: `//${Vue.domains.imgs}/imgs/light/main/light-main.png`,
  lightMainLightBeijing: `//${Vue.domains.imgs}/imgs/light/main/light-Beijing.png`,

  lightMainAlarmCommon: `//${Vue.domains.imgs}/imgs/light/main/alarm-common.png`,
  lightMainAlarmFetal: `//${Vue.domains.imgs}/imgs/light/main/alarm-fetal.png`,
  lightMainAlarmPre: `//${Vue.domains.imgs}/imgs/light/main/alarm-pre.png`,
  lightMainBoxAlarm: `//${Vue.domains.imgs}/imgs/light/main/box-alarm.png`,
  lightMainRateOnline: `//${Vue.domains.imgs}/imgs/light/main/online-rate.png`,
  lightMainRateOpen: `//${Vue.domains.imgs}/imgs/light/main/open-rate.png`,
  siteMainBack: `//${Vue.domains.imgs}/imgs/main/back.png`,
  siteMainCenter: `//${Vue.domains.imgs}/imgs/main/center.png`,
  siteMainLeftTop: `//${Vue.domains.imgs}/imgs/main/left-top.png`,
  siteMainLeftCenter: `//${Vue.domains.imgs}/imgs/main/left-center.png`,
  siteMainLeftBottom: `//${Vue.domains.imgs}/imgs/main/left-bottom.png`,
  siteMainRightTop: `//${Vue.domains.imgs}/imgs/main/right-top.png`,
  siteMainRightCenter: `//${Vue.domains.imgs}/imgs/main/right-center.png`,
  siteMainRightBottom: `//${Vue.domains.imgs}/imgs/main/right-bottom.png`,
  siteMainReturn: `//${Vue.domains.imgs}/imgs/main/return.png`,
  val: function (v, point) {
    // console.log('img val', v, typeof v)
    if (v == '-' || v == '--') return v;
    if (v == -2) return '-';
    if (v == -1) return '-';
    if (v == undefined || v == null || v < 0) return '--';
    v = parseFloat(v);
    if (v < 0.00001) v = 0;
    let p = point || 0;
    return v.toFixed(p);
  },
};
const MyImages = {
  install: function (Vue) {
    Object.defineProperties(Vue.prototype, {
      img: {
        get() {
          return imgs;
        }
      },
    });
  }
}

Vue.use(MyImages)

export default MyImages;