import Vue from 'vue'

const state = {
  connectTypes: [], //灯杆设备接入方式
  productCodes: { //产品类型编码
    cust: 'root',
    group: 'group',
    station: 'Station', //站点
    powerBox: 'PowerBox', //配电柜产品编码
    monitor: 'LightMonitor', //单灯集中器
    light: 'LightStation', //单灯产品编码
    pole: 'PoleStation', //灯杆站点编码
    video: 'VideoStation', //视频站点编码
    env: 'EnvStation', //环测站点编码
    led: 'LedStation', //LED站点编码
    lux: 'LuxStation', //光控站点编码
  },
  mediaTypes: {
    1: '视频',
    2: '语音',
    3: '图片'
  }, //媒体类型
  mediaAccepts: { //媒体接收类型
    1: 'audio/mp4,video/mp4,video/mpeg',
    2: 'audio/mpeg',
    3: 'image/png,image/gif,image/jpeg',
  },
  playTaskTypes: {
    1: '按周',
    2: '特定日期',
    3: '节假日'
  }, //任务计划类型
  months: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
  // mapCenter: {lng: 113.828333, lat: 22.770866, zoom: 16}, //深圳地图默认中心与缩放级别
  mapCenter: {
    lng: 116.413387,
    lat: 39.910924,
    zoom: 16
  }, //北京地图默认中心与缩放级别
  pageSizes: [20, 100, 500, 1000, 5000],//分页初始化
  pageLayouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
  deviceTypes: {
    // dms7210: '3DMS-7210物联网关(ID11位)',
    // dms7210v2: '3DMS-7210物联网关(ID20位)',
    // amdm8051: '3DMS-8051采集器',
    // c229: '集中控制器C229',
    // c228: '集中控制器C228',
    // jwcacv1: '市电集中器协议AMDM-JWC-ACV1',
    // st6036: '集中器ST6036',
    // dms8088: '3DMS-8088-IVs监控器(正立)',
    // dms8088iv: '3DMS-8088-IV监控器',
    // dms8088iii: '3DMS-8088-III监控器',
    // lt118n: 'Lt118n单灯控制器',
    // uk17: 'UK17智慧单灯电源',
    // acd22v1: '单灯控制器联动版AC-D22-V1',
    // lt115n: 'Lt115n单灯控制器',
    // lt115ngyro: 'Lt115n单灯控制器百色专用版',
    // lt115n1: 'Lt115n单灯控制器陀螺仪版',
    // lt115nv1: 'Lt115n单灯控制器统一版',
    // ys6936bh: '羽声光照度温湿度传感器',
    // weatherstation: '小型超声波一体式气象站',
    // uk16: '小耳朵智能网关UK16',
    // groups: {
    //   dtus: {name: '数据传输单元', list: ['dms7210', 'dms7210v2']},
    //   boxes: {name: '配电柜监控设备', list: ['amdm8051','dms8088','dms8088iv','dms8088iii']},
    //   lights: {name: '单灯监控设备', list: ['st6036','jwcacv1','c229','c228','lt118n','uk17','acd22v1','lt115n','lt115ngyro','lt115n1','lt115nv1']},
    //   luxes: {name: '光控设备', list: ['ys6936bh']},
    //   envs: {name: '环测设备', list: ['weatherstation']},
    //   powers: {name: '电源设备', list: ['uk16']},
    // },
  },
  assetTypes: {
    1: '配电柜',
    2: '支路',
    3: '灯杆',
    4: '灯具',
    5: '线缆'
  }, //资产使用类型
  powerProductCode: 'PowerBox', //配电柜产品编码
  lightProductCode: 'LightStation', //单灯产品编码
  lightMonitorCode: 'LightMonitor', //单灯集中器
  weeks: {
    0: '星期日',
    1: '星期一',
    2: '星期二',
    3: '星期三',
    4: '星期四',
    5: '星期五',
    6: '星期六',
    7: '星期日'
  },
  weeks1: {
    0: '周日',
    1: '周一',
    2: '周二',
    3: '周三',
    4: '周四',
    5: '周五',
    6: '周六',
    7: '周日'
  },
  ampm: {
    0: '上午',
    1: '下午'
  },
  powerBoxTypes: {
    1: '配电室',
    2: '箱变',
    3: '落地式',
    4: '柱挂式',
    5: '墙挂式',
    6: '嵌入式'
  }, //配电柜类型
  lightTypes: {
    1: '市电常规型',
    2: '市电太阳能混合',
    3: '市电带倾斜',
    4: '双色温',
    5: '485单灯'
  }, //单灯类型
  newLightTypes: {
    101: '市电常规型',
    102: '市电太阳能混合',
    103: '市电带倾斜',
    104: '双色温',
    105: '485单灯'
  }, //新单灯类型
  leakagAlarmeStatus: {
    0: '正常',
    1: '一级漏电',
    2: '二级漏电',
    3: '三级漏电'
  },
  topDevices: { //配电柜通信设备枚举
    dms7210: '3DMS-7210物联网关(ID11位)',
    dms7210v2: '3DMS-7210物联网关(ID20位)',
    amdm8051: '3DMS-8051采集器',
    amdm8051bj: '3DMS-8051采集器(北京)',
  },
  luxDevices: { //光控设备类型枚举
    ys6936bh: '羽声光照度温湿度传感器',
  },
  monitorDevices: { //配电柜监控设备枚举
    dms8088: '3DMS-8088-IVs监控器(正立)',
    dms8088iv: '3DMS-8088-IV监控器',
    dms8088iii: '3DMS-8088-III监控器',
  },
  lightTopDevices: { //单灯通信设备枚举
    dms7210: '3DMS-7210物联网关',
    c229: '集中控制器C229',
    c228: '集中控制器C228',
    jwcacv1: '市电集中器协议AMDM-JWC-ACV1',
    st6036: '集中器ST6036',
    mqtt: 'NB-MQTT协议',
    cat1: 'CAT1协议'
  },
  lightMonitorDevices: { //单灯监控设备枚举
    lt118n: 'Lt118n单灯控制器',
    uk17: 'UK17智慧单灯电源',
    acd22v1: '单灯控制器联动版AC-D22-V1',
    lt115n: 'Lt115n单灯控制器',
    lt115ngyro: 'Lt115n单灯控制器陀螺仪版',
    lt115nv1: 'Lt115n单灯控制器统一版',
  },
  monitorDeviceTypes: { //集中器设备枚举
    c228: '集中控制器C228',
    c229: '集中控制器C229',
    jwcacv1: '市电集中器协议AMDM-JWC-ACV1',
    st6036: '集中器ST6036',
  },
  controlModes: { //单灯控制方式
    0: '计时日表',
    1: '准时日表',
    2: '光控'
  },
  powerBoxPartTypes: {
    PowerBoxLock: '门锁',
    PowerBoxDoor: '柜门',
    PowerBoxSmoke: '烟雾',
    PowerBoxWater: '水浸',
    PowerBoxMain: '总配电',
    PowerBoxBranch: '支路配电',
    PowerBoxContact: '接触器',
    PowerBoxSwitch: '转换器',
    PowerBoxOutput: '控制开关',
  },
  powerBoxAlarmTypes: {
    NoVoltage: '失压',
    LossPhase: '缺相',
    OverVoltage: '过压',
    UnderVoltage: '欠压',
    OverCurrent: '过流',
    UnderCurrent: '欠流',
    SlightLeakage: '轻微漏电',
    SeriousLeakage: '严重漏电',
    OverPayload: '过载',
    UnderPayload: '欠载',
    Steal: '被盗',
    Fault: '故障',
    UnusualLight: '非正常亮灯',
    CloseLight: '大片灭灯',
    OverTemp: '过温',
    DoorOpen: '柜门打开',
    LockOpen: '门锁打开',
    OutputFault: '控制输出异常',
    ContactFault: '接触器异常',
    SmokeFault: '烟雾',
    WaterFault: '水浸',
  },
  controlChannelCount: { //控制通道数量
    amdm8051: 4,
    amdm8051bj: 4,
    dms8088: 7,
    dms8088iv: 7,
    dms8088iii: 7,
  },
  currentChannelCount: { //测量电流通道数量
    none: 0,
    amdm8051: 9,
    amdm8051bj: 12,
    dms8088: 72,
    dms8088iv: 72,
    dms8088iii: 70,
    '3dms8051b': 12,
    '3dms8051s': 12,
    dms8088siv: 72,
    st6038: 12,
  },
  leakageChannelCount: { //漏电电流通道数量
    none: 0,
    amdm8051: 3,
    amdm8051bj: 12,
    '3dms8051b': 12,
    '3dms8051s': 12,
    dms8088: 0,
    dms8088iv: 24,
    dms8088iii: 0,
    dms8088siv: 24,
    st6038: 3,
  },
  steelChannelCount: { //防盗电流通道数量
    none: 0,
    amdm8051: 8,
    amdm8051bj: 24,
    '3dms8051b': 12,
    '3dms8051s': 12,
    dms8088: 0,
    dms8088iv: 24,
    dms8088iii: 0,
    dms8088siv: 24,
    st6038: 0,
  },
  poleDevices: { //灯杆挂接设备列表
    ledInfo: 'LED广告屏', //由上海熙讯y60b20 LED控制器组成的LED大屏产品
    videoStation: '视频监控', //视频监测站
    lightStation: '单灯', //单灯
    powerStation: '小耳朵电源管理', //小耳朵电源管理
    envStation: '环境监测站', //环境监测站
  },
  poleType: { //灯杆类型
    1: '智慧灯杆',
    2: '普通灯杆',
  },
  lightTableTypes: {
    1: '计时日表',
    2: '准时日表'
  }, //单灯时间表类型
  powerTableTypes: {
    1: '年表',
    0: '周表'
  },
  powerTableModes: {
    1: '北京',
    0: '常规'
  },
  powerTableActions: {
    0: '关闭',
    1: '开启',
    2: '无效'
  },
  monitorActionTypes: {
    0: '恒功率',
    1: '联动',
    2: '恒照度'
  },
  alarmTypes: {}, //平台所有报警枚举值
  alarmGroupList: {}, //所有报警分组
  alarmLevels: { //报警等级名称
    10: '预报警',
    20: '普通报警',
    30: '严重报警',
  },
  dealAlarmTypes: { //报警处理方式
    1: '等待处理',
    2: '手工消除，以下时间内不再报警',
    // 3: '调整阈值',
  },
  dateTimeFormats: [{
      name: '年',
      key: 'yyyy'
    },
    {
      name: '年月',
      key: 'yyyy-MM'
    },
    {
      name: '年月日',
      key: 'yyyy-MM-dd'
    },
    {
      name: '年月日时',
      key: 'yyyy-MM-dd HH'
    },
    {
      name: '年月日时分',
      key: 'yyyy-MM-dd HH:mm'
    },
    {
      name: '年月日时分秒',
      key: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      name: '年月日时分秒毫秒',
      key: 'yyyy-MM-dd HH:mm:ss.fff'
    },
  ],
  flowFormTypes: {
    1: '字符串',
    2: '整数',
    3: '实数',
    4: '日期时间',
    98: '数据字典',
    99: '数据源',
  },
  flowStepTypes: {
    0: '开始步骤',
    1: '与步骤',
    2: '或步骤',
    // 9: '结束步骤',
  },
  filterOperations: [ //过滤条件操作符号
    {
      code: '>=',
      name: '大于或等于',
      types: [2, 3, 4, 96]
    },
    {
      code: '>',
      name: '大于',
      types: [2, 3, 4, 96]
    },
    {
      code: '=',
      name: '等于',
      types: [1, 2, 3, 4, 5, 96, 97, 98, 99]
    },
    {
      code: '<',
      name: '小于',
      types: [2, 3, 4, 96]
    },
    {
      code: '<=',
      name: '小于或等于',
      types: [2, 3, 4, 96]
    },
    {
      code: 'c',
      name: '包含',
      types: [1, 97, 98, 99]
    },
  ],
  bigTypes: [], //设备大类
  smallTypes: [], //设备小类
  powerBoxStatus: {
    1: '正常',
    2: '维修',
    3: '停用',
  },
  comNames: { //8088IV多串口板子串口列表
    1: '[8088IV]子串口a',
    2: '[8088IV]子串口b或[6038]485-1端口',
    3: '[8088IV]子串口c或[6038]485-2端口',
    4: '[8088IV]子串口d或[6038]485-3端口',
    5: '[8088IV]子串口e',
    6: '[8088IV]子串口f或[6038]PLC模组端口',
  },
  comTypes: ['RS232', 'RS485'],
  comBuads: ['300bps', '600bps', '1200bps', '2400bps', '4800bps', '9600bps', '19200bps', '38400bps', '57600bps', '115200bps'],
  comDatas: ['6位', '7位', '8位', '9位'],
  comParitys: ['无校验', '奇校验', '偶校验'],
  comStops: ['1个停止位', '2个停止位'],
  coms8088names: { //8088IV多串口板子串口列表
    1: '[8088IV]子串口a',
    2: '[8088IV]子串口b或[6038]485-1端口',
    3: '[8088IV]子串口c或[6038]485-2端口',
    4: '[8088IV]子串口d或[6038]485-3端口',
    5: '[8088IV]子串口e',
    6: '[8088IV]子串口f或[6038]PLC模组端口',
  },
  com8088types: {
    0: 'RS232方式',
    1: 'RS485方式',
  },
  com8088buads: {
    1: '300bps',
    2: '600bps',
    3: '1200bps',
    4: '2400bps',
    5: '4800bps',
    6: '9600bps',
    7: '19200bps',
    8: '38400bps',
    9: '57600bps',
    10: '115200bps',
  },
  com8088datas: {
    6: '6位',
    7: '7位',
    8: '8位',
    9: '9位',
  },
  com8088paritys: {
    0: '无校验',
    1: '奇校验',
    2: '偶校验',
  },
  com8088stops: {
    1: '1个停止位',
    2: '2个停止位',
  },
  poleTypeNames: [{
      type: 0,
      code: 'pole',
      name: '灯杆'
    },
    {
      type: 199,
      code: 'light',
      name: '单灯'
    },
    // {type: 102, code: 'light', name: '单灯'},
    // {type: 103, code: 'light', name: '单灯'},
    // {type: 104, code: 'light', name: '单灯'},
    // {type: 105, code: 'light', name: '单灯'},
    {
      type: 301,
      code: 'video',
      name: '视频'
    },
    {
      type: 302,
      code: 'led',
      name: '广告屏'
    },
    {
      type: 303,
      code: 'env',
      name: '环境监测'
    },
    {
      type: 304,
      code: 'broad',
      name: '广播'
    },
    {
      type: 305,
      code: 'speak',
      name: '对讲'
    },
    {
      type: 306,
      code: 'wifi',
      name: 'WIFI'
    },
    {
      type: 307,
      code: 'charge',
      name: '充电桩'
    },
    {
      type: 309,
      code: 'power',
      name: '电源网关'
    },
    {
      type: 310,
      code: 'garbage',
      name: '智能垃圾桶'
    },
    {
      type: 311,
      code: 'lock',
      name: '智能锁'
    },
    {
      type: 312,
      code: 'phone',
      name: '手机充电'
    },
    {
      type: 320,
      code: 'manhole',
      name: '智能井盖'
    },
  ],
  poleTypes: {
    1: '总配电',
    2: '转换开关',
    3: '控制输出',
    4: '接触器',
    5: '支路配电',
    6: '柜门',
    7: '门锁',
    8: '烟雾',
    9: '水浸',
    10: '线缆',
    11: '漏电监测',
    12: '电压监测',
    199: '单灯',
    // 102: '单灯',
    // 103: '单灯',
    // 104: '单灯',
    // 105: '单灯',
    301: '视频监控',
    302: 'LED屏',
    303: '环境监测',
    304: '广播',
    305: '可视对讲',
    306: 'WIFI',
    307: '充电桩',
    309: '电源设备',
    310: '智能垃圾桶',
    311: '智能锁',
    312: '手机充电',
    320: '井盖状态',
  },
}
const mutations = {
  setBigSmallTypes: function (state, params) {
    state.bigTypes = params.bigs;
    state.smallTypes = params.types;
  },
  setConnectTypes: function (state, params) {
    state.connectTypes = params;
  },
  setDeviceTypes: function (state, types) {
    types.groups.boxes.list.push('st6038')
    state.deviceTypes = types;
  },
  setAlarmTypes: function (state, params) {
    state.alarmTypes = params.data;
    let groups = {};
    for (let key in params.data) {
      let group = '';
      if (key >= 1 && key <= 10) {
        group = params.method('com.lighting.V.alarm'); //'电压报警';
      } else if (key >= 11 && key <= 20) {
        group = params.method('com.lighting.I.alarm'); //'电流报警';
      } else if (key >= 21 && key <= 30) {
        group = params.method('com.power.alarm'); // '功率报警';
      } else if (key >= 31 && key <= 40) {
        group = params.method('com.security.alarm'); // '安防报警';
      } else if (key >= 41 && key <= 100) {
        group = params.method('com.other.alarm'); // '其他报警';
      } else {
        continue;
      }
      if (groups[group]) {
        groups[group].push(key);
      } else {
        groups[group] = [key];
      }
    }
    state.alarmGroupList = groups;
  },
}
const actions = {
  getBigSmallTypes: function (context) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/enum/DeviceTypes`, {}).then(res => {
        if (res.code === 0) {
          context.commit('setBigSmallTypes', res.data);
        }
        resolve(res);
      });
    });
  },
  getConnectTypes: function (context) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/enum/ConnectTypes`, {}).then(res => {
        if (res.code === 0) {
          context.commit('setConnectTypes', res.data);
        }
        resolve(res);
      });
    });
  },
  getDeviceTypes: function (context) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/common/type/gettypes`, {}).then(res => {
        if (res.code === 0) {
          res.data.type.groups = res.data.groups;
          delete res.data.groups;
          context.commit('setDeviceTypes', res.data.type);
        }
        resolve(res);
      });
    });
  },
  getAlarmTypes: function (context) {
    return new Promise(resolve => {
      Vue.$axios.get(`//${Vue.domains.trans}/station/alarm/type`).then(res => {
        if (res.code === 0) {
          context.commit('setAlarmTypes', {
            data: res.data,
            method: context.rootGetters['auth/showLang']
          });
        }
        resolve(res);
      });
    });
  },
}
const getters = {
  poleParamTypeName: function (state) {
    return function (type) {
      let n = state.poleTypes[type];
      if (!n) return '-';
      return n;
    }
  },
  getFieldTypeName: function (state) {
    return function (type) {
      let fts = state.flowFormTypes.filter(p => p.id == type);
      if (fts.length == 0) return '-';
      return fts[0].name;
    }
  },
  getBigTypeName: function (state) {
    return function (type) {
      let cts = state.bigTypes.filter(p => p.code == type);
      if (cts.length == 0) return '-';
      return cts[0].name;
    }
  },
  getSmallTypeName: function (state) {
    return function (type) {
      let cts = state.smallTypes.filter(p => p.code == type);
      if (cts.length == 0) return '-';
      return cts[0].name;
    }
  },
  getSmallTypes: function (state) {
    return function (type) {
      return state.smallTypes.filter(p => p.type == type);
    }
  },
  getDealAlarmMethod: function (state) {
    return function (params) {
      if (!params.dealType) return '';
      switch (params.dealType) {
        case 2:
          return `${state.dealAlarmTypes[params.dealType]}，并延期至${params.method.expire}`;
        default:
          return `${state.dealAlarmTypes[params.dealType]}`;
      }
    }
  },
  getConnectTypeName: function (state) {
    return function (type) {
      let cts = state.connectTypes.filter(p => p.code == type);
      if (cts.length == 0) return '-';
      return cts[0].name;
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};