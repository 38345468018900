import Vue from 'vue'
import ws from '../../plugins/ws'
import en from '../../assets/i18n/en_us.json'
import zh from '../../assets/i18n/zh_cn.json'
const tools = {
  clearStorage: function () {
    localStorage.setItem('token', null);
    localStorage.setItem('user', null);
    localStorage.setItem('ops', null);
    localStorage.setItem('appTypes', null);
    localStorage.setItem('apptype', null);
    localStorage.setItem('custid', 0);
  },
}

const state = {
  langs: 'zh', //语言记录
  langFile: {
    en: en,
    zh: zh,
  },
  token: null, //用户登录密钥
  user: {}, //用户相关信息
  ops: [], //用户操作列表
  customerId: 0, //当前登录用户所属的客户ID
  customers: [], //当前用户可访问的客户列表，
  appType: '', //当前用户进入的子系统类型，
  appTypes: [], // 用户可进入的所有应用场景类型
  roles: [], //当前管理角色列表
  organs: [], //当前所有单位列表
  appScenes: [], //当前所有应用类型列表
  config: {}, //当前用户进入的所在应用所对应的客户配置
  organTypes: { //单位职能类型
    1: '管理单位',
    2: '管养单位',
    3: '系统维护单位'
  },
  agentChangeValue: 0, //用户权限变化，需要重新加载数据时变更
  checkPswdTime: 0, //上次验证密码时间，暂时设置十分钟内免验密码
  showStationMenu: 'base', //显示站点菜单
  stationTabName: 'power', //站点单点标签页切换，单灯（light）或配电柜（power）
  showSpin: false, //是否显示加载浮窗
  spinContent: '处理中...', //加载浮窗显示内容
  spinTimeout: 10, //加载浮窗超时自动关闭
  currentMenuName: 'home', //灯杆系统当前目录
  sliderMenus: [ //灯杆侧边菜单
    {
      code: 'light',
      name: '路灯管理'
    },
    {
      code: 'video',
      name: '视频监控'
    },
    {
      code: 'broad',
      name: '广播管理'
    },
    {
      code: 'speak',
      name: '一键报警'
    },
    {
      code: 'led',
      name: '广告屏'
    },
    {
      code: 'env',
      name: '环境监测'
    },
    {
      code: 'manhole',
      name: '智能井盖'
    },
    {
      code: 'charge',
      name: '充电桩'
    },
    {
      code: 'wifi',
      name: '城市WIFI'
    },
    {
      code: 'garbage',
      name: '智能垃圾桶'
    },
    // {code: 'lock', name: '智能锁'},
    {
      code: 'phone',
      name: '手机充电'
    },
    {
      code: 'traffic',
      name: '公共交通'
    },
  ],
  urls: { //灯杆菜单路径
    home: '/index/pole/home', ///index/lightindex
    pole: '/index/pole/monitor/pole',
    gis: '/index/pole/monitor/gis',
    asset: '/index/pole/asset',
    analyse: '/index/pole/monitor/analyse',
    union: '/index/pole/monitor/union',
    business: '/index/pole/business',
    system: '/index/pole/system',

    light: '/index/pole/monitor/light',
    video: '/index/pole/monitor/video',
    broad: '/index/pole/monitor/broad',
    speak: '/index/pole/monitor/speak',
    led: '/index/pole/monitor/led',
    env: '/index/pole/monitor/env',
    wifi: '/index/pole/monitor/wifi',
    charge: '/index/pole/monitor/charge',
    manhole: '/index/pole/monitor/manhole',
    garbage: '/index/pole/monitor/garbage',
    lock: '/index/pole/monitor/lock',
    phone: '/index/pole/monitor/phone',
    traffic: '/index/pole/monitor/traffic',
  },
}
const mutations = {
  setPoleMenuName: function (state, menu) {
    state.currentMenuName = menu;
  },
  showSpin: function (state, {
    content,
    timeout
  }) {
    state.spinContent = content || '处理中...';
    state.spinTimeout = timeout || 10;
    state.showSpin = true;
  },
  hideSpin: function (state) {
    state.showSpin = false;
  },
  setStationMenu: function (state, name) {
    if (state.showStationMenu == name) {
      state.showStationMenu = 'base';
    } else {
      state.showStationMenu = name;
    }
  },
  switchStationTabName: function (state, name) {
    state.stationTabName = name;
  },
  setLastChecktime: function (state) {
    state.checkPswdTime = new Date().getTime();
  },
  init: function (state) {
    state.token = localStorage.getItem('token');
    state.user = JSON.parse(localStorage.getItem('user'));
    // state.ops = JSON.parse(localStorage.getItem('ops'));
    state.appTypes = JSON.parse(localStorage.getItem('appTypes'));
    state.appType = localStorage.getItem('apptype');
    state.customerId = localStorage.getItem('custid');
  },
  login: function (state, params) {
    state.token = params.token;
    // state.ops = params.ops;
    state.appType = params.curApp;
    state.customerId = params.curCust;
    state.appTypes = params.appScenes;
    // delete params.ops;
    delete params.appScenes;
    delete params.curApp;
    delete params.curCust;
    delete params.token;
    state.user = params;
    localStorage.setItem('token', state.token);
    // localStorage.setItem('ops', JSON.stringify(state.ops));
    localStorage.setItem('user', JSON.stringify(state.user));
    localStorage.setItem('appTypes', JSON.stringify(state.appTypes));
    localStorage.setItem('apptype', state.appType);
    localStorage.setItem('custid', state.customerId);
  },
  logout: function (state) {
    state.token = null;
    state.user = {};
    state.ops = [];
    state.customerId = 0;
    state.appType = '';
    state.appTypes = [];
    tools.clearStorage();
  },
  // setCustomers: function(state, custs){
  //   state.customers = custs;
  // },
  setAppType: function (state, appType) {
    state.appType = appType;
    localStorage.setItem('apptype', appType);
  },
  switchAppCust: function (state, params) {
    // console.log('switch app cust', params);
    state.appType = params.app;
    state.customerId = params.cust;
    state.config = params.cfg;
    state.ops = params.ops;
    localStorage.setItem('apptype', state.appType);
    localStorage.setItem('custid', state.customerId);
    ws.sendHeart();
    state.agentChangeValue++;
  },
  setLang: function (state, params) {
    state.langs = params;
  }
}
const actions = {
  checkPswd: function (context, form) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/auth/Check`, form).then(res => {
        if (res.code == 0) {
          context.commit('setLastChecktime');
        }
        resolve(res);
      });
    });
  },
  login: function (context, form) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/auth/login`, form).then(res => {
        if (res.code === 0) {
          context.commit('login', res.data);
        }
        resolve(res);
      });
    });
  },
  loginSms: function (context, form) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/auth/LoginSms`, form).then(res => {
        if (res.code === 0) {
          context.commit('login', res.data);
        }
        resolve(res);
      });
    });
  },
  logout: function (context) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/auth/logout`, {
        token: context.state.token
      }).then(() => {
        context.commit('logout');
        resolve(true)
      });
    });
  },
  switchAppCust: function (context, params) {
    return new Promise(resolve => {
      if (!context.state.token) {
        resolve({
          code: 990008,
          data: '请重新登录'
        });
        return;
      }
      Vue.$axios.post(`//${Vue.domains.trans}/station/auth/Switch`, params).then(res => {
        if (res.code === 0) {
          context.commit('switchAppCust', {
            app: params.app,
            cust: params.cust,
            cfg: res.data.cfg,
            ops: res.data.ops
          });
          context.commit('group/setRootTitle', res.data.cfg.name, {
            root: true
          });
          // const lang = localStorage.getItem('lighting_lang_str')
          // lang == null ? 'zh_cn' : lang;
          // Vue.$axios.post(`//${Vue.domains.trans}/station/auth/FetchLang`, {lang: lang}).then(res => {
          //   if(res.code === 0){
          //     context.commit('setLang', res.data);
          //   }
          //   resolve(res);
          // });
        }
        resolve(res);
      });
    });
  },
  reqFile: function (context, {
    url,
    args,
    title
  }) {
    return new Promise(resolve => {
      Vue.$axios.request({
        url: url,
        method: 'post',
        data: args,
        responseType: 'blob'
      }).then(res => {
        // console.log('req file', res)
        resolve(res);
        if (res.type == 'application/json')return;
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = title //`${}.xlsx`
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      });
    });
  },
}
const getters = {
  showLang: function (state) {
    return function (code, ...args) {
      state.langs= localStorage.getItem('lighting_system_lang');
      let langFile = state.langFile[state.langs];
      if (!langFile) return '未设置语言';
      let format = langFile[code]
      if (!format) return '未设置语言';
      for (let arg of args) {
        format = format.replace("{}", arg);
      }
      return format;
    }
  },
  funCodes: function (state) {
    return function () {
      if (state.user.isSuper) return true;
      if (!state.ops) return false;
      let codes = [...arguments];
      if (codes.indexOf('___') != -1) return true;
      // console.log('test fun codes', state.user, arguments, codes, state.ops)
      return state.ops.filter(function (v) {
        return codes.indexOf(v) != -1
      }).length > 0;
    }
  },
  needCheckPswd: function () {
    return true;
    // return (new Date().getTime() - state.checkPswdTime) > 10 * 60 * 1000;
  },
  custAndType: function (state) {
    return `${state.appType}:${state.customerId}`;
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};