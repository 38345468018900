<template>
  <div class="bg">
    <div class="helpCenter">
      <img src="../assets/lang_switch.png" alt="" />
      切换语言
      <Select v-model="langActive" style="width:100px;margin-left: 5px;">
        <Option v-for="item in langList" :value="item.code" :key="item.code">{{ item.name }}</Option>
      </Select>
    </div>
    <div class="conBox">
      <img src="../assets/logo.png" class="logo" alt="" />
      <p class="title1">城市照明智慧监控系统</p>
      <p class="title2">URBAN LIGHTING INTELLIGENT MONITORING SYSTEM</p>
      <!-- <p class="title3">让照明变得越来越智慧</p> -->
      <div class="loginBox">
        <div class="login-tabs">
          <div class="login-tab" :class="[isLoginWay==0?'tab-active':'']" @click="isLoginWay=0">账号登录</div>
          <div class="login-tab" :class="[isLoginWay==1?'tab-active':'']" @click="isLoginWay=1">短信登录</div>
          <div class="login-tab" :class="[isLoginWay==2?'tab-active':'']" @click="isLoginWay=2">扫码登录</div>
        </div>
        <div class="input" v-if="isLoginWay==0">
          <div class="inputBox">
            <img src="../assets/user.png" alt="" />
            <input type="text" v-model="form.name" placeholder="请输入账号" maxlength="20"
              @keypress="keypress($event, 'name')" />
            <div class="isPswd">
              <Checkbox v-model="isPswd">记住密码</Checkbox>
            </div>
          </div>
          <div class="inputBox">
            <img src="../assets/pswd.png" alt="" />
            <input ref="pswd" type="password" v-model="form.pswd" maxlength="20" placeholder="请输入密码"
              @keypress="keypress($event, 'pswd')" />
            <img src="../assets/eye.png" alt="" @click="eyeBut()" class="eye" />
          </div>
          <input type="button" value="登录" class="signIn" @click="validate" />
          <!-- type=password  修改类型查看密码 -->
          <ModalModifyPswd v-model="showModifyModal" :user="form.name" />
        </div>
        <div class="input" v-if="isLoginWay==1">
          <div class="inputBox">
            <img src="../assets/phone.png" alt="" />
            <input type="text" v-model="formPhone.phone" placeholder="请输入手机号" maxlength="20"
              @keypress="keypress($event, 'phone')" />
          </div>
          <div class="inputBox">
            <img src="../assets/pswd.png" alt="" />
            <input type="text" v-model="formPhone.code" maxlength="4" placeholder="验证码" 
              @keypress="keypress($event, 'code')" :key="codeIndex"/>
            <div class="inputCode" :class="[isDisabled?'isDisabled':'']" @click="getCode" :key="codeCopywriting">
              {{codeCopywriting}}</div>
          </div>
          <input type="button" value="登录" class="signIn" @click="loginSms" />
        </div>
      </div>
      <div class="appImg" v-if="domains.appUrl &&  domains.appUrl !='' && isLoginWay !=2">
        <img :src="img.app.appLogo" alt="">
        <p>手机扫码下载APP</p>
      </div>
      <div class="qrCodeBox" v-if="isLoginWay==2 && qrCode!='' ">
        <img :src="`//${domains.trans}/station/auth/ShowQrCode?id=${qrCode}`" alt="" class="qrCodeBox-img">
        <p class="qrCodeBox-p">请使用智慧照明app扫码登陆</p>
        <Button type="primary" @click="isShowAppLogo = true"><img :src="img.app.download" alt=""> 下载智慧照明app</Button>
      </div>
    </div>
    <Modal v-model="isShowAppLogo" title="手机扫码下载安装智慧照明APP" width="400">
      <img :src="img.app.appLogo" alt="" style="width:200px;height: 200px;margin-left: 85px;">
    </Modal>
    <div class="icon-bottom">
      <div class="iconList">
        <div class="icon">
          <img src="../assets/lighting.png" alt="" />
          <p>照明监控</p>
        </div>
        <div class="icon">
          <img src="../assets/devOps.png" alt="" />
          <p>运维管理</p>
        </div>
        <div class="icon">
          <img src="../assets/assets.png" alt="" />
          <p>资产管理</p>
        </div>
        <div class="icon">
          <img src="../assets/analysis.png" alt="" />
          <p>数据分析</p>
        </div>
        <div class="icon">
          <img src="../assets/police.png" alt="" />
          <p>报警中心</p>
        </div>
      </div>
    </div>
    <div class="icp-bottom" v-if="domains.needBeian">
      <a href="https://beian.miit.gov.cn" target="_blank" style="margin-right: 5px">粤ICP备2021067683号-1</a>
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602005852#"
        target="_blank">粤公安备44030602005852号</a>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapState } from "vuex";
import { localData } from '@/plugins/cookie'
import ModalModifyPswd from '@/views/common/modals/ModalModifyPswd'
Vue.prototype.$localData = { localData }
export default {
  name: "Home",
  components: {
    ModalModifyPswd,
  },
  computed: {
    ...mapState("auth", ["token", "user", "appType", "customerId", "appTypes"]),
  },
  data: () => ({
    beian: require("../assets/beian.png"),
    valid: false,
    agree: true,
    hover: true,
    redirect: null,
    langActive: 'zh',
    langList: [
      { name: '中文', code: 'zh' },
      { name: 'English', code: 'en' },
    ],
    form: {
      name: "",
      pswd: "",
    },
    codeCopywriting: '获取验证码',
    formPhone: {
      phone: "",
      code: "",
    },
    isShowAppLogo: false,
    time: 60,
    timer: null,
    isDisabled: false,
    isPswd: false,
    showModifyModal: false,
    isLoginWay: 0,
    codeIndex: 0,
    qrCode: '',
    rule: {
      name: [
        { required: true, message: "用户名不能为空", trigger: "blur" },
        {
          type: "string",
          min: 2,
          max: 10,
          message: "用户名长度必须2 - 10个字符",
          trigger: "blur",
        },
      ],
      pswd: [
        { required: true, message: "密码不能为空", trigger: "blur" },
        {
          type: "string",
          min: 5,
          max: 10,
          message: "密码长度必须6 - 10个字符",
          trigger: "blur",
        },
      ],
    },
  }),
  watch: {
    'formPhone.phone'() {
      this.formPhone.code='';
      this.codeIndex++;
    },
    langActive() {
      localStorage.setItem("lighting_system_lang", this.langActive)
      this.langAjax();
    },
    isLoginWay() {
      if (this.isLoginWay == 2) {
        this.getQrCode();
      }
    }
  },
  mounted: function () {
    localStorage.setItem('token', null);
    localStorage.setItem('user', null);
    let account = this.getCookie("account");
    let password = this.getCookie("password");
    if (account) {
      this.form.name = account
      this.form.pswd = password
      this.isPswd = true
    }
    let lang = localStorage.getItem("lighting_system_lang")
    if (lang != null) {
      this.langActive = lang;
    } else {
      localStorage.setItem("lighting_system_lang", 'zh')
      this.langActive = 'zh';
    }
    this.langAjax();
    window.eventBus.$on('qrcodeDataComing', params => {
      if (params.code == this.qrCode && params.success) {
        this.$store.commit('auth/login', params.token);
        setTimeout(() => {
          let scenes = this.appTypes.filter(p => p.code == this.appType);
          if (scenes.length == 0) {
            this.$Message.warning("用户当前未配置任何权限，请联系管理员");
            return;
          }
          this.$router.push(scenes[0].url);
        }, 50);
      }
    })
  },
  destroyed: function () {
    window.eventBus.$off('qrcodeDataComing');
  },
  methods: {
    getQrCode() {
      this.$axios.post(`//${Vue.domains.trans}/station/auth/GetQrCode`, {}).then(res => {
        if (res.code == 0) {
          this.qrCode = res.data;
          this.$store.commit('cmd/enableQrCode', res.data);
        }
      });
    },
    getCode() {
      if (!this.isPhone()) {
        this.$Message.warning("请填写正确的手机号");
        return false
      }
      if (this.isDisabled) { return false }
      Vue.$axios.post(`//${Vue.domains.trans}/station/auth/GetSmsCode`, { mobile: this.formPhone.phone }).then((res) => {
        if (res.code == 0) {
          this.isDisabled = true;
          this.timer = setInterval(() => {
            if (this.time == 0) {
              clearInterval(this.timer);
              this.isDisabled = false;
              this.codeCopywriting = '获取验证码';
              this.time = 60;
            } else {
              this.codeCopywriting = this.time + '秒';
              this.time--;
            }
          }, 1000);
        }
      })

    },
    langAjax() {
      this.$store.commit('auth/setLang', this.langActive);
      // this.$axios.post(`//${this.domains.trans}/station/auth/FetchLang`, { lang: this.langActive }).then(res => {
      //   if (res.code === 0) {
      //   }
      // });
    },
    eyeBut() {
      this.$refs.pswd.type = "text";
      setTimeout(() => {
        if (this.$refs.pswd) {
          this.$refs.pswd.type = "password";
        }
      }, 3000);
    },
    keypress: async function (e, n) {
      if (e.keyCode == 13) {
        switch (n) {
          case 'name':
            if (this.form.name.length == 0 || this.form.name.length > 20) {
              this.$Message.warning("用户名不能为空且长度不超过20字符");
              return;
            } else {
              this.$refs.pswd.focus({ cursor: "all" });
            }
            break;
          case 'pswd':
            if (this.form.pswd.length == 0 || this.form.pswd.length > 20) {
              this.$Message.warning("密码不能为空且长度不超过20字符");
              return;
            } else {
              this.validate();
            }
            break;
          case 'phone':
            if (this.isPhone()) {
              this.$Message.warning("手机号格式不正确");
              return;
            }
            break;
          case 'code':
            if (this.formPhone.code.length == 0 || this.formPhone.code.length > 4) {
              this.$Message.warning("手机号验证码格式错误");
              return;
            } else {
              this.loginSms();
            }
            break;
        }
      }
    },
    isPhone() {
      let pattern = /^1[3456789]\d{9}$/;
      return pattern.test(this.formPhone.phone);
    },
    loginSms: async function () {
      if (!this.isPhone) {
        this.$Message.warning("手机号格式不正确");
        return;
      }
      if (this.formPhone.code.length == 0 || this.formPhone.code.length > 4) {
        this.$Message.warning("手机号验证码格式错误");
        return;
      }
      this.$store.commit("auth/setAppType", "");
      let ajaxData = {
        code: this.formPhone.code,
        mobile: this.formPhone.phone
      }
      this.$store.dispatch("auth/loginSms", ajaxData).then((res) => {
        this.isDisabled = false;
        this.time = 60;
        if (res.code == 0) {
          let scenes = this.appTypes.filter(p => p.code == this.appType);
          if (scenes.length == 0) {
            this.$Message.warning("用户当前未配置任何权限，请联系管理员");
            return;
          }
          this.$router.push(scenes[0].url);
          return;
        } else {
          this.$Message.warning("用户名不能为空且长度不超过20字符");
          return;
        }
      });
    },
    validate: async function () {
      if (this.form.name.length == 0 || this.form.name.length > 20) {
        this.$Message.warning("用户名不能为空且长度不超过20字符");
        return;
      }
      if (this.form.pswd.length == 0 || this.form.pswd.length > 20) {
        this.$Message.warning("密码不能为空且长度不超过20字符");
        return;
      }
      this.$store.commit("auth/setAppType", "");
      this.$store.dispatch("auth/login", this.form).then((res) => {
        if (res.code == 0) {
          this.setUserInfo();
          if (res.data.modify) {
            let that = this;
            this.$Modal.warning({
              title: "必须修改密码",
              content: "密码过于简单，不符合政府规定",
              onOk: function () {
                that.showModifyModal = true
              }
            });
            return;
          }
          let scenes = this.appTypes.filter(p => p.code == this.appType);
          if (scenes.length == 0) {
            this.$Message.warning("用户当前未配置任何权限，请联系管理员");
            return;
          }
          if(this.$router.currentRoute.path=="/loginHome"){//判断只展示首页
             this.$router.push("/index/light/road/indexHome");
             return;
          }
          this.$router.push(scenes[0].url);
          return;
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    setUserInfo: function () {
      if (this.isPswd) {
        this.setCookie("account", this.form.name)
        this.setCookie("password", this.form.pswd)
      } else {
        this.setCookie("account", "")
        this.setCookie("password", "")
      }
    },
    // 获取cookie
    getCookie: function (key) {
      if (document.cookie.length > 0) {
        let start = document.cookie.indexOf(key + '=')
        if (start !== -1) {
          start = start + key.length + 1
          let end = document.cookie.indexOf(';', start)
          if (end === -1) end = document.cookie.length
          return unescape(document.cookie.substring(start, end))
        }
      }
      return ''
    },
    // 保存cookie
    setCookie: function (cName, value, expiredays) {
      let exdate = new Date()
      exdate.setDate(exdate.getDate() + expiredays)
      document.cookie = cName + '=' + decodeURIComponent(value) +
        ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
    },
  },
};
</script>
<style scoped>
.bg {
  background-image: url(../assets/body.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center 0;
}
.helpCenter {
  font-size: 16px;
  font-weight: 400;
  color: #f2f4f9;
  position: fixed;
  right: 80px;
  top: 50px;
  display: inline-block;
  z-index: 50000;
}
.helpCenter img {
  vertical-align: middle;
  margin-right: 5px;
}
.helpCenter i {
  vertical-align: middle;
  font-style: inherit;
  text-decoration: underline;
}
.logo {
  width: 105px;
  height: 104px;
}
.conBox {
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 50%;
  margin-top: -350px;
}
.title1 {
  font-size: 50px;
  font-family: Microsoft YaHei;
  color: #ffffff;
  margin-top: 48px;
}
.title2 {
  font-size: 18px;
  font-family: Arial;
  color: #ffffff;
}
.appImg {
  width: 150px;
  height: 110px;
  margin: 60px auto 0;
}
.appImg img {
  width: 110px;
  height: 110px;
  position: relative;
  z-index: 20;
}
.appImg p {
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.icon-bottom {
  position: fixed;
  width: 100%;
  bottom: 90px;
}
.iconList {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.iconList .icon {
  margin: 0 35px;
}
.iconList .icon p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 18px;
}
.loginBox {
  width: 920px;
  margin: 30px auto 0;
  position: relative;
}
.input {
  display: flex;
  justify-content: center;
}
.input .inputBox {
  position: relative;
}
.input .inputBox img {
  position: absolute;
  left: 29px;
  top: 24px;
  z-index: 50;
}
.input .inputBox .inputCode {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 125px;
  height: 54px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  border-radius: 0 5px 5px 0;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid #009dff;
  padding: 0 15px;
  background-color: #009dff;
  line-height: 54px;
}
.input .inputBox .inputCode.isDisabled {
  opacity: 0.5;
}
.input .inputBox img.eye {
  left: 308px;
  top: 27px;
  cursor: pointer;
}
.input .inputBox .isPswd {
  position: absolute;
  left: 2px;
  top: 77px;
  width: 100px;
  font-size: 14px;
  color: #b2d3ff;
}
.input .signIn {
  width: 149px;
  height: 54px;
  background: #009dff !important;
  border-radius: 6px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  text-indent: 0;
}
.icp-bottom {
  position: absolute;
  bottom: 30px;
  text-align: center;
  height: 25px;
  width: 100%;
}
.icp-bottom a {
  color: #aab0be;
  font-size: 14px;
}
.input input {
  width: 343px;
  height: 54px;
  margin: 10px;
  display: flex;
  color: #aab0be;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff !important;
  position: relative;
  border-radius: 6px;
  border: 0;
  outline-style: none;
  align-self: stretch;
  line-height: 54px;
  text-indent: 54px;
}
.input input::-webkit-input-placeholder {
  /* WebKit browsers */
  font-size: 16px;
  background-color: #ffffff !important;
  position: relative;
  color: #aab0be;
  line-height: 54px;
  text-indent: 54px;
}
.input input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 16px;
  background-color: #ffffff !important;
  position: relative;
  color: #aab0be;
  line-height: 54px;
  text-indent: 54px;
}
.input input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 16px;
  background-color: #ffffff !important;
  font-weight: 400;
  position: relative;
  color: #aab0be;
  line-height: 54px;
  text-indent: 54px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 111111s;
  -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
}
.login-tabs {
  height: 48px;
  flex: none;
  display: flex;
  margin-left: 10px;
  margin-bottom: 5px;
}
/* login-tab" :class="[isLoginWay?'tab-active */
.login-tab {
  padding: 2px 10px;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 48px;
  opacity: 0.6;
}
.login-tab.tab-active {
  color: #ffffff;
  opacity: 1;
}
.tab-active::after {
  content: "";
  width: calc(75%);
  height: 2px;
  display: block;
  margin: -5px auto 0;
  border-bottom: 3px solid #009dff;
}
.qrCodeBox {
  text-align: center;
}
.qrCodeBox-img {
  width: 180px;
  height: 180px;
  display: inline-block;
}
.qrCodeBox-p {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin: 10px 0;
}
</style>